<template>
	<div class="account-modal" @click="showDeleteConfirm = false">
		<h2 class="title">Account</h2>

		<form>
			<div class="input-container">
				<label>Email:</label>
				<p>{{ user.email }}</p>
			</div>
			<div class="input-container">
				<label>Current plan:</label>
				<p>
					{{ user.role.name === 'Authenticated' ? 'Free' : user.role.name }}
					<button 
						type="button" 
						v-if="user.role.name !== 'Authenticated'" 
						style="margin-left: 10px;"
						@click="openPortal"
					>Manage</button>
					<button 
						type="button" 
						v-else
						style="margin-left: 10px;"
						@click="openPricing"
					>Pricing</button>
					<a 
						href="https://releasecat.io/pricing.php" 
						target="_blank" 
						style="display: none;" 
						ref="pricingLink"
					></a>
				</p>
			</div>
			<div class="input-container" v-if="!user.auth0id.includes('google')">
				<label>Reset password:</label>
				<button type="button" @click="resetPassword">Click to reset</button>
			</div>
			<div class="input-container">
				<label>Delete account:</label>
				<div class="delete-container" :class="{'active': showDeleteConfirm}">
					<button type="button" @click.stop="showDeleteConfirm = true">Click to delete</button>
					<div class="confirm" v-if="showDeleteConfirm">
						<p>Are you sure? Remember, you cannot undo this action! Your account will be unable to restore. </p>
						<button type="button" @click.stop="deleteUser">Confirm delete</button>
					</div>
				</div>
			</div>
		</form>

		<div class="loading" v-if="isLoading"></div>
		<div class="buttons" v-else>
			<button 
				type="button"
				class="secondary cancel"
				@click="closeModal"
			>Close</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AccountModal',
	data: () => ({
		showDeleteConfirm: false,
		isLoading: false
	}),
	computed: {
		user() {
			return this.$store.getters.user;
		}
	},
	methods: {
		async resetPassword() {
			try {
				const message = await this.$store.dispatch('resetPassword', this.user.email);
				this.$toast.open({
					type: 'success',
					message
				});
				this.closeModal();
			} catch (e) {
				console.error(e);
				this.$toast.open({
					message: 'Something went wrong. Please contact us.',
					type: 'error'
				});
			}
		},

		async deleteUser() {
			try {
				this.showDeleteConfirm = false;
				this.isLoading = true;
				await this.$store.dispatch('deleteUser');
				await this.$store.dispatch('logout');
			} catch (e) {
				console.error(e);
				this.$toast.open({
					message: 'Something went wrong. Please contact us.',
					type: 'error'
				});
			} finally {
				this.isLoading = false;
			}
		},

		closeModal() {
			this.$emit('close');
		},

		async openPortal() {
			try {
				this.isLoading = true;
				const response = await this.$store.dispatch('openPortal');
				window.location.href = response.url;
			} catch (e) {
				console.error(e)
			} finally {
				this.isLoading = false;
			}
		},

		openPricing() {
			this.$refs.pricingLink.click();
		},
	}
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/_variables'
@import '../../assets/sass/_mixins'

.account-modal
	@include modal-layout
	.title
		@include modal-title	
	form
		@include modal-form
		p
			font-size: 17px
		button
			font-size: 16px
			text-transform: uppercase
			color: $accent-color
			transition: .1s linear
			&:hover
				cursor: pointer
				color: $accent-active-color
		.delete-container
			width: 195px
			padding: 10px 20px
			transform: translate(-20px, -10px)
			&.active
				border: 1px solid $border-color
				box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
			.confirm
				p
					color: $text-black-color
					margin-top: 10px
					font-size: 12px
				button
					margin: 15px 0 5px
					background: $accent-active-color
					color: #fff
					padding: 8px 16px
					font-size: 14px
	.loading
		@include modal-loading
	.buttons
		@include modal-buttons


</style>