<template>
	<div 
		class="fields-editor" 
		:class="{'onboarding': isFieldTypesOnboarding}"
		@mouseup="stopDragging" 
		@mouseleave="stopDragging"
	>
		<ul 
			class="types" 
			v-click-outside="hideHeadersMenu"
		>
			<div class="cover" v-if="isAddingLoader || isDeletingLoader"></div>
			<li 
				v-for="(type, index) in types" 
				:key="index"
				@click.stop="addField(type)"
				:class="type.toLowerCase()"
			>
				<!-- <img :src="getTypeIconSrc('accent', type)" /> -->
				<i class="icon"></i>
				<span class="name">{{ type }}</span>
			</li>
			<transition mode="out-in" name="menu">
				<div class="headers-menu" v-if="isShowHeadersMenu">
					<button 
						type="button" 
						:style="getHeaderStyle('Header 1')" 
						@click="addField('Header 1')"
					>Header 1</button>
					<button 
						type="buton" 
						:style="getHeaderStyle('Header 2')"
						@click="addField('Header 2')"
					>Header 2</button>
				</div>
			</transition>
		</ul>

		<ul
			class="fields"
			v-click-outside="resetActiveField"
			ref="fieldsContainer"
			@mousemove="moveDragging"
		>
			<li
				v-for="field in fields"
				:key="field.id"
				@click="setActiveField(field)"
				:class="{
					active: activeField && activeField.id === field.id,
					hidden: !isFieldVisible(field),
				}"
				:ref="'field-' + field.id"
			>
				<div class="body">
					<button
						class="move"
						v-if="
							!isAddingLoader &&
							!isDeletingLoader &&
							!field.attributes.relatedGroupId
						"
						@mousedown.stop="startDragging($event, field)"
					></button>

					<span
						class="move-placeholder"
						v-else-if="isAddingLoader || isDeletingLoader"
					></span>
					<span class="move-placeholder" v-else></span>

					<img 
						:src="getTypeIconSrc('grey', field.attributes.type)" 
						:style="{
							'margin-right': field.attributes.type === 'html' ? '0' : '15px',
							'height': field.attributes.type.startsWith('header') ? '26px' : '28px',
						}"
					/>

					<FieldInput :fieldId="field.id" @click.stop="" />

					<button
						class="delete"
						@click.stop="deleteField(field)"
						v-if="!isDeletingLoader && !isAddingLoader"
					></button>
					<span class="delete-placeholder" v-else></span>
				</div>
				<div class="toolbar-container">
					<transition mode="out-in" name="toolbar">
						<Toolbar
							:fieldId="field.id"
							v-if="activeField && activeField.id === field.id"
						/>
					</transition>
				</div>
			</li>
		</ul>

		<div class="banner-menu" v-if="fields.length">
			<button
				class="preview"
				@click="previewSidePanel"
			>Preview Side Panel</button>
			<button
				class="preview"
				@click="previewPopup"
			>Preview Popup</button>
			<button 
				class="publish"
				@click="publishBanner"
				v-if="!isPublished"
			>Publish</button>
			<button
				class="unpublish"
				@click="unpublishBanner"
				v-else
			>Unpublish</button>
		</div>

		<div class="background-input-container">
			<p>
				Post background color:
			</p>
			<button 
				class="color-picker"
				type="button"
				@click="openColorPicker"
			></button>
			<input 
				type="color" 
				ref="bgColorInput" 
				style="display: none;"
				v-model="postBackgroundColor"
			>
			<input 
				type="text"
				class="input-color"
				v-model="postBackgroundColor"
			>
		</div>
	</div>
</template>

<script>
import FieldInput from "@/components/FieldInput";
import Toolbar from "@/components/Toolbar";

export default {
	name: "FieldsEditor",
	components: {
		FieldInput,
		Toolbar,
	},
	data: () => ({
		types: [
			"Date",
			"Headers",
			"Text",
			"Image",
			"Html",
			"Youtube",
			"List",
			"Combo",
		],

		isShowHeadersMenu: false,
		activeListId: null,
		isAddingLoader: false,
		isDeletingLoader: false,

		dragging: null,
		fieldsOffsets: {},

		postBackgroundColor: "#ffffff",
		postBackgroundColorSetTimeout: null,
	}),
	computed: {
		activeBanner() {
			return this.$store.getters.activeBanner;
		},
		isPublished() {
			return this.activeBanner.attributes.isPublished;
		},
		fields() {
			return this.$store.getters.fields;
		},
		activeField() {
			return this.$store.getters.activeField;
		},
		isFieldTypesOnboarding() {
			return this.$store.getters.banners.length === 1 && this.fields.length === 0;
		}
	},
	methods: {
		getTypeIconSrc(color, type) {
			const imageName = type
				.toLowerCase()
				.replaceAll(" ", "_")
				.replaceAll("_group", "");

			if (color === "accent") {
				return require(`../assets/images/dashboard/types/${imageName}.svg`);
			} else if (color === 'grey') {
				if (type === 'header_1') {
					return require(`../assets/images/dashboard/types/header_1_grey.png`);
				} else if (type === 'header_2') {
					return require(`../assets/images/dashboard/types/header_2_grey.png`);
				}
				return require(`../assets/images/dashboard/types/${imageName}_grey.svg`);
			}
		},

		isFieldVisible(field) {
			return (
				!field.attributes.relatedGroupId ||
				field.attributes.relatedGroupId === this.activeListId
			);
		},

		getHeaderStyle(type) {
			type = type.toLowerCase().replace(' ', '_');
			const template = this.$store.getters.templates.find(
				(t) => t.attributes.type === type
			).attributes.styles;

			return {
				...template,
				'font-size': '14px',
			};
		},

		hideHeadersMenu() {
			this.isShowHeadersMenu = false;
		},

		async addField(
			type,
			options = {
				active: true,
				focus: true,
				groupId: null,
				index: null,
				changeLoadingState: true,
			}
		) {
			
			if (options.changeLoadingState) {
				this.isAddingLoader = true;
			}

			type = type.toLowerCase().replaceAll(" ", "_");

			if (type === 'headers') {
				this.isShowHeadersMenu = !this.isShowHeadersMenu;
				this.isAddingLoader = false;
				return;
			}

			this.isShowHeadersMenu = false;

			const styles = this.$store.getters.templates.find(
				(t) => t.attributes.type === type
			).attributes.styles;

			let value = "";
			if (type === "date") value = this.activeBanner.attributes.releaseDate;
			else if (type === "combo") {
				const functionIcon = this.$store.getters.comboIcons.find(
					(i) => i.attributes.data.name === "Function"
				);
				value = functionIcon.attributes.image.data.attributes.url;

				const fieldsGroups = [];
				this.fields.forEach((f) => {
					if (f.attributes.type === "combo") {
						fieldsGroups.push(f);
					}
				});

				if (fieldsGroups.length % 2 === 0) {
					styles["background-color"] = "#f5f6f7";
				} else {
					styles["background-color"] = "transparent";
				}
			}

			let index = options.index;
			if (index === null) {
				index = this.fields.length;
			}

			const fieldData = {
				type,
				value,
				index,
				banner: this.activeBanner.id,
				styles,
			};
			if (options.groupId) {
				fieldData.relatedGroupId = options.groupId;
			}

			let newFieldOptions = {};
			if (options.focus) {
				newFieldOptions.focus = true;
			}

			try {

				const field = await this.$store.dispatch("createField", {
					fieldData,
					options: newFieldOptions,
				});
				if (options.active) {
					this.setActiveField(field);
				}
	
				const promises = [];
				if (type === "combo") {
					this.activeListId = field.id;
					const promise1 = this.addField("Header 1 Group", {
						active: false,
						focus: false,
						groupId: field.id,
						index: ++index,
						changeLoadingState: false,
					});
					promises.push(promise1);
					const promise2 = this.addField("Header 2 Group", {
						active: false,
						focus: false,
						groupId: field.id,
						index: ++index,
						changeLoadingState: false,
					});
					promises.push(promise2);
					const promise3 = this.addField("Text group", {
						active: false,
						focus: false,
						groupId: field.id,
						index: ++index,
						changeLoadingState: false,
					});
					promises.push(promise3);
				}
	
				if (promises.length > 0) {
					await Promise.all(promises);
					this.sortFieldsByIndexes();
				}
	
				if (options.changeLoadingState) {
					this.isAddingLoader = false;
				}
			} catch (e) {
				this.$toast.open({
					message: e.response?.data.error.message || e,
					type: "error",
				});
			}
		},

		setActiveField(field) {
			if (field.attributes.type === "combo") {
				this.activeListId = field.id;
				this.$nextTick(() => {
					this.updateTextareas();
				});
			} else if (!field.attributes.relatedGroupId) {
				this.activeListId = null;
			}
			this.$store.commit("setActiveField", field);
		},

		resetActiveField() {
			this.activeListId = null;
			this.$store.commit("setActiveField", null);
		},

		sortFieldsByIndexes() {
			const sortedFields = this.fields.sort(
				(a, b) => a.attributes.index - b.attributes.index
			);
			this.$store.commit("setFields", sortedFields);
		},

		async updateFieldsIndexes() {
			const promises = [];

			this.fields.forEach((field, index) => {
				if (field.attributes.index !== index) {
					const newData = { ...field.attributes, index };
					this.$store.commit("setField", {
						id: field.id,
						data: newData,
					});
					const promise = this.$store.dispatch("updateField", {
						id: field.id,
						data: { ...newData, banner: this.activeBanner.id },
						updateState: false,
					});
					promises.push(promise);
				}
			});

			if (promises.length > 0) {
				await Promise.all(promises);
			}

			await this.updateHtmlIfIsPublished();
		},

		getComboGroup(field) {
			const relatedFields = this.fields.filter(
				(f) => f.attributes.relatedGroupId === field.id
			);
			relatedFields.unshift(field);
			return relatedFields;
		},

		updateTextareas() {
			const textareas = document.querySelectorAll("textarea");
			for (let i = 0; i < textareas.length; i++) {
				this.$autosize(textareas[i]);
				this.$autosize.update(textareas[i]);
			}
		},

		async deleteField(field) {
			try {
				this.isDeletingLoader = true;
				if (field.attributes.type === "combo") {
					const promises = [];

					const comboGroup = this.getComboGroup(field);
					comboGroup.forEach((f) => {
						this.$refs["field-" + f.id][0].style.display = "none";
						const promise = this.$store.dispatch("deleteField", f.id);
						promises.push(promise);
					});

					await Promise.all(promises);
				} else {
					await this.$store.dispatch("deleteField", field.id);
				}

				await this.updateFieldsIndexes();

				this.isDeletingLoader = false;
			} catch (e) {
				const message = e.response?.data.error.message || e;
				this.$toast.open({
					message,
					type: "error",
				});
			}
		},

		startDragging(event, field) {
			let closeToolbarTimeout = 0;
			if (this.activeField) {
				this.resetActiveField();
				closeToolbarTimeout = 300;
			}

			setTimeout(() => {
				const fieldsContainer = this.$refs.fieldsContainer;
				const fieldNode = this.$refs["field-" + field.id][0];
				const fieldNodeRect = fieldNode.getBoundingClientRect();
				const fieldNodeClone = fieldNode.cloneNode(true);
				fieldNodeClone.style.position = "fixed";
				fieldNodeClone.style.top = fieldNodeRect.top + "px";
				fieldNodeClone.style.left = fieldNodeRect.left + "px";
				fieldNodeClone.style.width = fieldNodeRect.width + "px";
				fieldNodeClone.style.height = fieldNodeRect.height + "px";
				fieldsContainer.appendChild(fieldNodeClone);

				fieldNode.classList.add("dragging");

				this.dragging = {
					field,
					fieldNode: fieldNodeClone,
					originalFieldNode: fieldNode,
					neighbours: this.getFieldsNeighbours(field),
				};

				this.setFieldsOffsets();
			}, closeToolbarTimeout);
		},

		moveDragging(e) {
			if (this.dragging) {
				const currentOffset =
					e.clientY - this.dragging.fieldNode.offsetHeight / 2;

				this.dragging.fieldNode.style.top = currentOffset + "px";

				if (
					this.dragging.neighbours.top &&
					currentOffset <
						this.fieldsOffsets[this.dragging.neighbours.top.id].top
				) {
					this.swapAndUpdateNeighbours(
						this.dragging.field,
						this.dragging.neighbours.top
					);
				} else if (
					this.dragging.neighbours.bottom &&
					currentOffset >
						this.fieldsOffsets[this.dragging.neighbours.bottom.id].top
				) {
					this.swapAndUpdateNeighbours(
						this.dragging.field,
						this.dragging.neighbours.bottom
					);
				}
			}
		},

		swapAndUpdateNeighbours(draggingField, neighbourField) {
			const draggingFieldIndex = draggingField.attributes.index;
			const neighbourFieldIndex = neighbourField.attributes.index;

			const updatedFields = [];

			this.fields.forEach((f) => {
				if (f.attributes.index === draggingFieldIndex) {
					let neighbourFields = [];
					if (neighbourField.attributes.type === "combo") {
						neighbourFields = this.getComboGroup(neighbourField);
					} else {
						neighbourFields = [neighbourField];
					}
					neighbourFields.forEach((nf) => {
						updatedFields.push(nf);
					});
				} else if (f.attributes.index === neighbourFieldIndex) {
					let draggingFields = [];
					if (draggingField.attributes.type === "combo") {
						draggingFields = this.getComboGroup(draggingField);
					} else {
						draggingFields = [draggingField];
					}
					draggingFields.forEach((df) => {
						updatedFields.push(df);
					});
				} else if (!f.attributes.relatedGroupId) {
					let neutralFields = [];
					if (f.attributes.type === "combo") {
						neutralFields = this.getComboGroup(f);
					} else {
						neutralFields = [f];
					}
					neutralFields.forEach((nf) => {
						updatedFields.push(nf);
					});
				}
			});

			this.$store.commit("setFields", updatedFields);
			this.updateFieldsIndexes();

			this.$nextTick(() => {
				this.setFieldsOffsets();
				this.dragging.neighbours = this.getFieldsNeighbours(draggingField);
			});
		},

		setFieldsOffsets() {
			this.fields.forEach((field) => {
				const fieldNode = this.$refs["field-" + field.id][0];
				const fieldNodeRect = fieldNode.getBoundingClientRect();
				this.fieldsOffsets[field.id] = fieldNodeRect;
			});
		},

		getFieldsNeighbours(field) {
			const neighbours = {
				top: null,
				bottom: null,
			};
			this.fields.forEach((f) => {
				if (f.attributes.index === field.attributes.index - 1) {
					if (f.attributes.relatedGroupId) {
						neighbours.top = this.fields.find(
							(_f) => _f.id === f.attributes.relatedGroupId
						);
					} else {
						neighbours.top = f;
					}
				}

				if (f.attributes.index === field.attributes.index + 1) {
					if (f.attributes.relatedGroupId) {
						// If next field has related group, then it is a combo field
						const comboGroup = this.getComboGroup(field);
						const lastInComboGroup = comboGroup[comboGroup.length - 1];
						neighbours.bottom =
							this.fields.find(
								(_f) =>
									_f.attributes.index === lastInComboGroup.attributes.index + 1
							) || null;
					} else {
						neighbours.bottom = f;
					}
				}
			});

			return neighbours;
		},

		stopDragging() {
			if (this.dragging) {
				this.dragging.fieldNode.remove();
				this.dragging.originalFieldNode.classList.remove("dragging");
				this.dragging = null;
			}
		},

		async updateHtmlIfIsPublished() {
			if (this.activeBanner.attributes.isPublished) {
				this.$nextTick(async () => {
					const bannerNode = document.querySelector(".releasecat_banner");
					let html;
					if (bannerNode) {
						html = await this.$store.dispatch("generateHtml", bannerNode);
					} else {
						html = "";
					}

					this.$store.dispatch("updateBanner", {
						id: this.activeBanner.id,
						data: {
							html,
							stream: this.$store.getters.activeStream.id,
						},
					});
				});
			}
		},

		previewSidePanel() {
			this.$eventBus.$emit('previewSidePanel');
		},
		
		previewPopup() {
			this.$eventBus.$emit('previewPopup');
		},

		publishBanner() {
			this.$eventBus.$emit('publishBanner');
		},

		unpublishBanner() {
			this.$eventBus.$emit('unpublishBanner');
		},

		openColorPicker() {
			this.$refs.bgColorInput.click();
		}
	},
	mounted() {
		this.postBackgroundColor = this.activeBanner.attributes.styles.background;
	},
	watch: {
		activeBanner: {
			async handler(banner) {
				this.$store.commit("setFields", []);
				await this.$store.dispatch("getFields", banner.id);
				this.sortFieldsByIndexes();
				this.$store.commit('setActiveField', null);
			},
			immediate: true,
		},

		postBackgroundColor(value) {
			this.activeBanner.attributes.styles.background = value;

			if (this.postBackgroundColorSetTimeout) 
				clearTimeout(this.postBackgroundColorSetTimeout);

			this.postBackgroundColorSetTimeout = setTimeout(() => {
				const bannerStyles = { ...this.activeBanner.attributes.styles, background: value };
				this.$store.dispatch("updateBanner", {
					id: this.activeBanner.id,
					data: {
						styles: bannerStyles,
						stream: this.$store.getters.activeStream.id,
					},
				});
			}, 300);
		},

		fields: {
			handler() {
				if (
					this.fields.length === 1 
					&& this.$store.getters.banners.length === 1
					&& !this.$store.getters.user.isBoarded
				) {
					setTimeout(() => {
						this.$eventBus.$emit('highlightResourcesMenu');
					}, 15000);
					this.$store.dispatch('updateUser',{
						id: this.$store.getters.user.id,
						isBoarded: true
					});
				}
			}
		}
	},
};
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'
.fields-editor
	padding: 0 30px
	padding-bottom: 50px
	width: 100%
	position: relative
	flex: 1
	&.onboarding 
		padding-top: 10px
		transition: padding .2s linear
		ul.types
			animation-name: onboarding
			animation-duration: 1s
			animation-iteration-count: infinite
			animation-direction: alternate
			animation-timing-function: linear
			box-shadow: 0 0 0 0 rgba($accent-color, .5)
			padding: 5px 0
			&:hover
				-webkit-animation: none
	ul.types
		list-style: none
		display: flex
		justify-content: space-around
		position: relative
		width: 100%
		.cover
			display: block
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: calc(100% + 20px)
			z-index: 9
			background-color: rgba(255, 255, 255, 0.3)
			cursor: progress
		li
			display: flex
			flex-direction: column
			align-items: center
			cursor: pointer
			position: relative
			width: 100%
			color: red
			&:hover
				.icon
					background: $accent-active-color
				.name
					color: $accent-active-color
			.icon
				width: 28px
				height: 28px
				background: $accent-color
				mask-repeat: no-repeat
				mask-size: contain
				mask-position: center
			.name
				color: $accent-color
				font-size: 10px
				margin-top: 5px
				text-transform: uppercase
				white-space: nowrap
				top: 100%
		li.date
			.icon
				mask-image: url('../assets/images/dashboard/types/date.svg')
				--mask-image: url('../assets/images/dashboard/types/date.svg')
		li.headers
			.icon
				mask-image: url('../assets/images/dashboard/types/headers.svg')
				--mask-image: url('../assets/images/dashboard/types/headers.svg')
		li.text
			.icon
				mask-image: url('../assets/images/dashboard/types/text.svg')
				--mask-image: url('../assets/images/dashboard/types/text.svg')
		li.image
			.icon
				mask-image: url('../assets/images/dashboard/types/image.svg')
				--mask-image: url('../assets/images/dashboard/types/image.svg')
		li.html
			.icon
				mask-image: url('../assets/images/dashboard/types/html.svg')
				--mask-image: url('../assets/images/dashboard/types/html.svg')
		li.youtube
			.icon
				mask-image: url('../assets/images/dashboard/types/youtube.svg')
				--mask-image: url('../assets/images/dashboard/types/youtube.svg')
		li.list
			.icon
				mask-image: url('../assets/images/dashboard/types/list.svg')
				--mask-image: url('../assets/images/dashboard/types/list.svg')
		li.combo
			.icon
				mask-image: url('../assets/images/dashboard/types/combo.svg')
				--mask-image: url('../assets/images/dashboard/types/combo.svg')
		.headers-menu
			position: absolute
			top: calc(100% + 30px)
			display: flex
			flex-direction: column
			z-index: 99
			left: 72px
			background: #fff
			padding: 5px 15px
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
			border: 1px solid $border-color
			width: 130px
			button
				text-align: left
				padding: 8px 0
				font-size: 14px
				&:not(:last-child)
					border-bottom: 1px solid #e6e6e6
				&:hover
					cursor: pointer
	ul.fields
		list-style: none
		margin-top: 30px
		width: 100%
		position: relative
		li
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
			cursor: pointer
			background: #fff
			border: 1px solid $border-color
			width: 100%
			&:hover
				.body
					border-color: $accent-active-color
					button
						opacity: 0.7
			&.active
				border-color: $text-black-color
				// background: $accent-light-color
			&.hidden
				margin-bottom: 0px !important
				border: none
				overflow: hidden
				.body
					max-height: 0px
					padding: 0
			&.dragging
				background: #f7f7f7

			&:not(:last-child)
				margin-bottom: 5px
			.body
				display: flex
				align-items: center
				padding: 15px
				transition: .2s linear
				img
					height: 28px
					width: 28px
					fill: #c4cbcf
				button
					opacity: 0
					transition: .1s linear
					&:hover
						opacity: 1
						cursor: pointer
					&.move
						width: 13px
						height: 17px
						background: url(../assets/images/dashboard/icons/move.png) no-repeat
						margin-right: 15px
						cursor: grab
					&.delete
						width: 15px
						height: 15px
						background: url(../assets/images/dashboard/icons/delete.png) no-repeat
						margin-left: 15px
				.move-placeholder
					display: block
					width: 13px
					height: 17px
					margin-right: 15px
				.delete-placeholder
					display: block
					width: 15px
					height: 15px
					margin-left: 15px
					cursor: progress
	.banner-menu
		display: none
	
	.background-input-container
		position: absolute
		bottom: 10px
		right: 30px
		display: flex
		align-items: flex-end
		font-size: 14px
		p
			transform: translateY(-2px)
		.color-picker
			display: inline-block
			width: 16px
			height: 16px
			background: url('../assets/images/dashboard/icons/drop.svg') no-repeat
			background-size: contain
			margin-left: 10px
			cursor: pointer
		.input-color
			border: none
			background: transparent
			outline: none
			margin-left: 3px
			border-bottom: 1px solid $accent-color
			padding-bottom: 2px
			width: 65px

@media screen and (max-width: 1440px)
	.fields-editor
		ul.types
			li
				.name
					display: none
		ul.fields
			margin-top: 20px

@media screen and (max-width: 1280px)
	.fields-editor
		padding-left: 0px
		padding-right: 20px
		ul.types
			li
				.name
					display: flex
		ul.fields
			margin-top: 40px
		.background-input-container
			right: 20px

@media screen and (max-width: 1100px)
	.fields-editor
		ul.types
			li
				.name
					display: none
		ul.fields
			margin-top: 20px

@media screen and (max-width: 1000px)
	.fields-editor
		padding-right: 0
		ul.types
			li
				.name
					display: flex
		ul.fields
			margin-top: 40px
		.banner-menu
			display: flex
			justify-content: center
			margin-top: 20px
			button
				color: $accent-color
				font-size: 15px
				text-transform: uppercase
				transition: .1s linear
				&:not(:last-child)
					margin-right: 20px
				&:hover
					cursor: pointer
					color: $accent-active-color
		.background-input-container
			right: 0

@media screen and (max-width: 600px)
	.fields-editor
		ul.types
			li
				.name
					display: none
		ul.fields
			margin-top: 20px
			.toolbar-container
				display: none
		.banner-menu
			button
				font-size: 13px

.toolbar-enter, .toolbar-leave-to
	max-height: 0
	transform: scaleY(0)

.toolbar-enter-active, .toolbar-leave-active
	transition: all 0.2s ease

.menu-enter, .menu-leave-to
	transform: translateY(-10px)
	opacity: 0

.menu-enter-active, .menu-leave-active
	transition: all .2s ease

@keyframes onboarding
	70%
		box-shadow: 0 0 0 10px rgba($accent-color, 0)
	100%
		box-shadow: 0 0 0 0 rgba($accent-color, 0)
</style>