<template>
  <div class="toolbar" v-if="field.type !== 'date'">
    <div 
      class="template template_text"
      v-if="field.type === 'header_1'
            || field.type === 'header_2'
            || field.type === 'text'
            || field.type === 'list'
            || field.type === 'header_1_group'
            || field.type === 'header_2_group'
            || field.type === 'text_group'"
    >
      <div class="params-row">
        <!-- Font color -->
        <div class="param-container param-container_color" v-tooltip="'Font color (Hex)'">
          <div
            class="param-color__A-container"
            @click="$refs['inputColor'].click()"
          >
            <span class="param-color__A">A</span>
            <div
              class="param-color__color-box"
              :style="{'background-color': field.styles['color']}">
            </div>
          </div>
          <input
            class="param-color__input-hex"
            type="text"
						:value="field.styles['color']"
            @input="setStyles('color', $event.target.value)"
          >
          <input
            class="param-color__input-hidden"
            type="color"
            ref="inputColor"
            :value="field.styles['color']"
						@input="setStyles('color', $event.target.value)"
          >
        </div>
        <!-- Font size -->
        <div class="param-container param-container_size" v-tooltip="'Font size'">
          <input
            class="param-size__input"
            type="text"
            :value="field.styles['font-size']"
						@input="setStyles('font-size', $event.target.value)"
            @keydown="arrowsHandler($event, 'font-size')"
          >
          <span class="param-size__unit">px</span>
        </div>
        <!-- Font weight -->
        <div
          class="param-container param-container_weight"
          @click="field.styles['font-weight'] === 'bold'
					? setStyles('font-weight', 'normal')
					: setStyles('font-weight', 'bold')"
          :class="{'active': field.styles['font-weight'] === 'bold'}"
          v-tooltip="'Font weight'"
        >
          <span class="param-weight__B">B</span>
        </div>
        <!-- Font style -->
        <div
          class="param-container param-container_style"
          :class="{'active': field.styles['font-style'] === 'italic'}"
          @click="field.styles['font-style'] === 'italic'
					? setStyles('font-style', 'normal')
					: setStyles('font-style', 'italic')"
          v-tooltip="'Font style'"
        >
          <span class="param-style__I">I</span>
        </div>
        <!-- Font decoration -->
        <div
          class="param-container param-container_decoration"
          :class="{'active': field.styles['text-decoration'] === 'underline'}"
          @click="field.styles['text-decoration'] === 'underline'
					? setStyles('text-decoration', 'none')
					: setStyles('text-decoration', 'underline')"
          v-tooltip="'Font decoration'"
        >
          <span class="param-decoration__U">U</span>
        </div>
        <!-- Text shadow  -->
        <div
          class="param-container param-container_shadow"
          :class="{'active': field.styles['text-shadow'] !== 'none'}"
          @click="field.styles['text-shadow'] === 'none'
					? setStyles('text-shadow', '1px 1px 1px rgba(0,0,0,0.5)')
					: setStyles('text-shadow', 'none')"
          v-tooltip="'Text shadow'"
        >
          <span class="param-shadow__S">S</span>
        </div>
        <!-- Margin top  -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            :value="field.styles['margin-top']"
						@input="setStyles('margin-top', $event.target.value)"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Default styles -->
        <div
          class="param-container param-container_default"
        >
          <button
            class="param-default__button"
            type="button"
            @click="setDefaultStyles"
          >Default</button>
        </div>
        <!-- End of row -->
      </div>
      <div class="params-row">
        <!-- Font family -->
        <div 
          class="param-container param-container_family" 
          v-tooltip="'Font family'"
          @click="showDropdown.family = !showDropdown.family"
        >
          <div class="param-family__active">
            {{ field.styles['font-family'] }}
          </div>
          <ul 
            class="param-family__dropdown"
            v-show="showDropdown.family"
          >
            <li
              class="param-family__dropdown-item"
              v-for="(font, index) in fonts"
              :key="index"
              :style="{'font-family': font, 'display': field.styles['font-family'] === font ? 'none' : 'block'}"
              @click="setStyles('font-family', font)"
            >
              {{ font }}
            </li>
          </ul>
        </div>
        <!-- Line height -->
        <div class="param-container param-container_line-height" v-tooltip="'Line height'">
          <input
            class="param-line-height__input"
            type="text"
            :value="field.styles['line-height']"
						@input="setStyles('line-height', $event.target.value)"
          >
          <span class="param-line-height__unit">em</span>
        </div>
        <!-- Alignments -->
        <div
          class="param-container param-container_alignment"
          :class="{'active': field.styles['text-align'] === alignment}"
          v-for="(alignment, index) in alignments"
          :key="index"
          @click="setStyles('text-align', alignment)"
          v-tooltip="alignment === 'justify' ? 'Justify' : 'Align ' + alignment"
        >
          <img
            class="param-alignment__image"
            :alt="alignment"
            :src="require(`../assets/images/toolbar/align-${alignment}.png`)"
          >
        </div>
        <!-- Margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
						:value="field.styles['margin-bottom']"
						@input="setStyles('margin-bottom', $event.target.value)"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- Link -->
        <div
          class="param-container param-container_link"
          :class="{'active': field.actionUrl || showLinkInput}"
        >
          <button
            class="param-link__button"
            type="button"
            @click="showLinkInput = !showLinkInput"
          >Link</button>
        </div>
        <!-- End of row -->
      </div>
      <div class="params-row" v-if="showLinkInput">
        <div class="link-container">
          <input
            class="link-container__input"
            type="text"
            ref="inputUrl"
            placeholder="Paste link with 'https://' protocol and press 'Enter'"
            @keyup.enter="addActionUrl"
            :value="field.actionUrl"
          >
        </div>
        <!-- End of row -->
      </div>
      <!-- End of text template -->
    </div>
    

    <!-- Image template -->
    <div 
      class="template template_image"
      v-if="field.type === 'image'"
    >
      <div class="params-row">
        <!-- Empty -->
        <div
          class="param-container disabled"
        >
          
        </div>

        <!-- Image fit -->
        <!-- <div
          class="param-container param-container_fit"
          v-tooltip="'Image fit'"
          @click="showDropdown.imageFit = !showDropdown.imageFit"
        >
          <div class="param-fit__active">
            {{ field.styles['object-fit'] }}
          </div>
          <div 
            class="param-fit__dropdown"
            v-show="showDropdown.imageFit"
          >
            <div
              class="param-fit__dropdown-item"
              v-for="(fit, index) in fits"
              :key="index"
              @click="onFitChange(fit)"
              :style="{'display': field.styles['object-fit'] === fit ? 'none' : 'block'}"
            >
              {{ fit }}
            </div>
          </div>
        </div> -->
        <!-- Image justify  -->
        <div
          class="param-container param-container_justification"
          v-for="(justify, index) in justifications"
          :key="index"
          :class="{'active': field.styles['justify-content'] === justify}"
          @click="onJustifyChange(justify)"
          v-tooltip="getJustifyTooltip(justify)"
        >
          <img
            class="param-alignment__image"
            :alt="alignments[index]"
            :src="require(`../assets/images/toolbar/align-${alignments[index]}.png`)"
          >
        </div>
        <!-- Image default styles -->
        <div
          class="param-container param-container_default"
        >
          <button
            class="param-default__button"
            type="button"
            @click="setDefaultStyles"
          >Default</button>
        </div>
        <!-- End of row -->
      </div>
      <div class="params-row">
				<!-- Image width selector -->
				<div 
					class="param-container param-container_width-selector"
					v-tooltip="'Image width'"
					@click="showDropdown.imageWidth = !showDropdown.imageWidth"
				>
					<div class="param-width-selector__active">
            <input
              class="param-width__input"
              type="text"
              :value="field.styles['width']" 
              @input="setStyles('width', $event.target.value)"
              @keydown="arrowsHandler($event, 'width')"
              @click.stop
            >
            <span class="param-width__unit" v-if="field.styles['width'] !== 'auto'">px</span>
					</div>
					<div 
						class="param-width-selector__dropdown"
						v-show="showDropdown.imageWidth"
					>
						<div
							class="param-width-selector__dropdown-item"
							v-for="(preset, index) in imageWidthPresets"
							:key="index"
              @click="setImageWidth(preset)"
            >
							<!-- :style="{'display': field.styles['width'] === width ? 'none' : 'block'}" -->
							{{ preset }}
						</div>
					</div>
				</div>
        <!-- Image margin top  -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            :value="field.styles['margin-top']"
						@input="setStyles('margin-top', $event.target.value)"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Image margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
            :value="field.styles['margin-bottom']"
						@input="setStyles('margin-bottom', $event.target.value)"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- Image link -->
        <div
          class="param-container param-container_link"
          :class="{'active': field.actionUrl || showLinkInput}"
        >
          <button
            class="param-link__button"
            type="button"
            @click="showLinkInput = !showLinkInput"
          >Link</button>
        </div>
        <!-- End of row -->
      </div>
      <transition name="input-link" mode="in-out">
        <div class="params-row" v-if="showLinkInput">
          <div class="link-container">
            <input
              class="link-container__input"
              type="text"
              ref="inputUrl"
              placeholder="Paste link with 'https://' protocol and press 'Enter'"
              @keyup.enter="addActionUrl"
              :value="field.actionUrl"
            >
          </div>
          <!-- End of row -->
        </div>
      </transition>
      <!-- End of image template -->
    </div>


    <!-- Video template -->
    <div 
      class="template template_video"
      v-if="field.type === 'youtube'"
    >
      <div class="params-row">
        <!-- Video height -->
        <div
          class="param-container param-container_height"
          v-tooltip="'Video height'"
        >
          <input
            class="param-height__input"
            type="text"
            :value="field.styles['height']"
						@input="setStyles('height', $event.target.value)"
            @keydown="arrowsHandler($event, 'height')"
          >
          <span class="param-height__unit" v-if="field.styles['height'] !== 'auto'">px</span>
        </div>
        <!-- Video margin top  -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            :value="field.styles['margin-top']"
						@input="setStyles('margin-top', $event.target.value)"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Video margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
            :value="field.styles['margin-bottom']"
						@input="setStyles('margin-bottom', $event.target.value)"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- Video default styles -->
        <div
          class="param-container param-container_default"
        >
          <button
            class="param-default__button"
            type="button"
            @click="setDefaultStyles"
          >Default</button>
        </div>
        <!-- End of row -->
      </div>
      <!-- End of video template -->
    </div>


    <!-- Combo template -->
    <div 
      class="template template_list"
      v-if="field.type === 'combo'"
    >
      <div class="params-row">
      <!-- Icon -->
        <div 
          class="param-container param-container_list-style" 
          v-tooltip="'Combo icon'"
          @click="showDropdown.comboIcon = !showDropdown.comboIcon"
        >
          <div class="param-list-style__active">
            {{ comboIconActive.name }}
          </div>
          <ul 
            class="param-list-style__dropdown"
            v-show="showDropdown.comboIcon"
          >
            <li
              class="param-list-style__dropdown-item"
              v-for="(comboIcon, index) in comboIcons"
              :key="index"
              @click="setComboIcon(comboIcon.data)"
              :style="{'display': comboIconActive.name !== 'custom' && comboIconActive.name === comboIcon.data.name ? 'none' : 'block'}"
            >
              {{ comboIcon.data.name }}
            </li>
            <li
              class="param-list-style__dropdown-item"
              @click="setComboIcon({ name: 'custom' })"
            >
              Custom
            </li>
          </ul>
          <input
            class="input-image input-image_hidden"
            :style="{'display': 'none'}"
            type="file"
            accept="image/*"
            @change="onComboImageChange"
            ref="inputComboIcon"
          >
        </div>
        <!-- Background color -->
        <div 
          class="param-container param-container_list-background" 
          v-tooltip="'Background color'"
          @click="showDropdown.comboBackground = !showDropdown.comboBackground"
        >
          <div class="param-list-background__active">
            Background
          </div>
          <ul 
            class="param-list-background__dropdown"
            v-show="showDropdown.comboBackground === true"
          >
            <li
              class="param-list-background__dropdown-item"
              v-for="(background, index) in listBackgrounds"
              :key="index"
              @click="setComboBackground(background)"
            >
              {{ background.name }}
            </li>
          </ul>
        </div>
      <!-- End of row -->
      </div>
    <!-- End of list template -->
    </div>
  <!-- End of toolbar -->
  </div>
</template>

<script>
import SaveFieldMixin from '@/mixins/saveField';

export default {
  props: {
		fieldId: {
			type: Number,
			required: true
		}
	},
	mixins: [SaveFieldMixin],
  data: () => ({
		updateFieldTimeout: null,

    weights:        ['normal', 'bold'],
    fonts:          ['Open Sans', 'Poppins', 'Mulish'],
    styles:         ['normal', 'italic'],
    decorations:    ['none', 'underline'],
    shadows:        ['none', '1px 1px #000'],
    alignments:     ['left', 'center', 'right', 'justify'],
    margins:        ['0', '5', '10', '15', '20', '30', '40', '50'],
    justifications: ['flex-start', 'center', 'flex-end'],
    fits:           ['none', 'cover', 'contain', 'fill'],

    comboIconActive: {
      name: 'Function'
    },
    comboColors: [
      {
        name: 'Yellow',
        color: '#de9905'
      },
      {
        name: 'Green',
        color: '#628c3c'
      },
      {
        name: 'Red',
        color: '#ab4922'
      },
      {
        name: 'Blue',
        color: '#2B579A'
      }
    ],
    listBackgrounds: [
      {
        name: 'Grey',
        color: '#f5f6f7'
      },
      {
        name: 'Transparent',
        color: 'transparent'
      } 
    ],
    listBackgroundActive: {
      name: 'Select'
    },
		imageWidthPresets: ['Auto', 'Full width', 'Custom'],

    showDropdown: {
      family: false,
      imageFit: false,
      comboIcon: false,
      comboBackground: false,
			imageWidth: false
    },
    showLinkInput: false
  }),
	computed: {
		field() {
			return this.$store.getters.field(this.fieldId).attributes;
		},
    comboIcons() {
      return this.$store.getters.comboIcons.map(icon => icon.attributes);
    },
		activeBanner() {
			return this.$store.getters.activeBanner;
		}
  },
  methods: {
    setImageWidth(presetName) {
      if (presetName === 'Auto') 
        this.setStyles('width', 'auto');
      else if (presetName === 'Full width')  {
        const bannerWidth = parseInt(this.activeBanner.attributes.styles.width);
        const bannerPaddingLeft = parseInt(this.activeBanner.attributes.styles['padding-left']);
        const bannerPaddingRight = parseInt(this.activeBanner.attributes.styles['padding-right']);
        this.setStyles('width', `${bannerWidth - bannerPaddingLeft - bannerPaddingRight}`);
      }
      else if (presetName === 'Custom') 
        this.setStyles('width', '100');
    },

		setStyles(attribute, value) {
			const styles = { ...this.field.styles, [attribute]: value };
			this.$store.commit('setField', {
        id: this.fieldId,
        data: { styles }
      });
      this.saveField({ styles, banner: this.activeBanner.id }, false);
		},

		setData(data) {
			this.$store.commit('setField', {
				id: this.fieldId,
				data
			});
			this.saveField({ ...data, banner: this.activeBanner.id }, true);
		},

    setDefaultStyles() {
			const defaultTemplate = this.$store.getters.templates.find(t => t.attributes.type === this.field.type);
			const styles = defaultTemplate.attributes.styles;
			this.setData({ styles });
    },

    arrowsHandler(e, type) {
      if (e.keyCode === 38) {
        // ARROW UP
        e.preventDefault();
        if (this.field.styles[type] === 'auto' || this.field.styles[type] === '100%') {
					this.setStyles(type, '100');
        } else {
					this.setStyles(type, parseInt(this.field.styles[type]) + 1);
        }
      } else if (e.keyCode === 40) {
        // ARROW DOWN
        e.preventDefault();
        if (this.field.styles[type] === 'auto' || this.field.styles[type] === '100%') {
          this.setStyles(type, '100');
        } else if (this.field.styles[type] > 0) {
          this.setStyles(type, parseInt(this.field.styles[type]) - 1);
        }
      }
    },

    addActionUrl() {
      let link = this.$refs['inputUrl'].value;
      let regExpression = /^(https:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

      if (link.startsWith('mailto:')) {
        regExpression = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;
      }

      const linkRegex = new RegExp(regExpression);

      if ( (link.startsWith('mailto:') ? link.slice(7).match(linkRegex) : link.match(linkRegex)) || link === "") {
				this.saveField({ actionUrl: link, banner: this.activeBanner.id }, true);
        this.showLinkInput = false;
      } else {
        alert('Incorrect link format');
      }
    },

    onJustifyChange(justify) {
      if ((this.field.type === 'image' ) && this.field.styles['width'] === '100%') {
				this.setStyles('width', 'auto');
				this.setStyles('object-fit', 'contain');
      }
			this.setStyles('justify-content', justify);
    },

    getJustifyTooltip(justify) {
      if (justify === 'flex-start') {
        return 'Align left';
      } else if (justify === 'center') {
        return 'Align center';
      } else if (justify === 'flex-end') {
        return 'Align right';
      }
    },

    onFitChange(fit) {
      if ((this.field.type === 'image') && this.field.styles['width'] === 'auto') {
				this.setStyles('width', '100%');
      }
			this.setStyles('object-fit', fit);
    },

    async onComboImageChange(e) {
      const targetImage = e.target.files[0];

      if (targetImage.size > 1048576) {
        this.$refs['inputImage'].value = "";
        alert('Maximum image size - 1MB');
        return;
      }

      const imageToDelete = this.field.image;

      if (imageToDelete && imageToDelete.data?.id) {
				await this.$store.dispatch('deleteImage', imageToDelete.data.id);
      }

			this.setData({ image: targetImage, value: '' });
			this.$eventBus.$emit('updateComboImageInput', this.fieldId);
    },

    async setComboIcon(iconData) {
      if (iconData.name === 'custom') {
        this.showDropdown.comboIcon = false;
        this.comboIconActive = { name: 'Custom' }
        this.$refs['inputComboIcon'].click();
        return;
      }

      const imageToDelete = this.field.image;

      if (imageToDelete && imageToDelete.data?.id) {
        await this.$store.dispatch('deleteImage', imageToDelete.data.id);
      }

      const comboIcon = this.comboIcons.find(icon => icon.data.name === iconData.name);

			this.setData({ image: { data: null }, value: comboIcon.image.data.attributes.url })

      // Update color in related combo fields
      const iconColor = this.comboColors.find(color => color.name === iconData.color);
      const relatedComboFields = this.$store.getters.fields.filter(field => field.attributes.relatedGroupId === this.fieldId);
      relatedComboFields.forEach(field => {
        this.$store.dispatch('updateField', {
          data: { 
            styles: { 
              ...field.attributes.styles, 
              color: (field.attributes.type === 'header_1_group' || field.attributes.type === 'header_2_group') 
                ? iconColor.color : field.attributes.styles.color
            },
						banner: this.activeBanner.id
          },
          id: field.id
        });
      });

      // this.$eventBus.$emit('updateComboImageInput', { id: this.fieldId, value: this.field.value });

      this.comboIconActive = { name: iconData.name };
    },

    setComboBackground(background) {
			this.setStyles('background-color', background.color);	
    }
  },
	async mounted() {
		// console.log(this.field)
    if (this.field.type === 'combo') {
      if (!this.field.value) {
        this.comboIconActive.name = 'Custom';
      } else if (~this.field.value.indexOf('update')) {
        this.comboIconActive = this.comboIcons.find(i => i.data.name === 'Update').data;
      } else if (~this.field.value.indexOf('bug_fix')) {
        this.comboIconActive = this.comboIcons.find(i => i.data.name === 'Bug fix').data;
      } else if (~this.field.value.indexOf('improve')) {
        this.comboIconActive = this.comboIcons.find(i => i.data.name === 'Improve').data;
      } 
    }
  },
  watch: {
    showLinkInput(bool) {
      if (bool) {
        this.$nextTick(() => {
          this.$refs['inputUrl'].focus();
        });
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'

@mixin input
  background: transparent
  border: none
  border-bottom: 1px solid $accent-color
  transition: border-color .1s linear
  padding: 2px 0
  color: $text-black-color
  font-weight: 400
  text-align: right
  font-size: 14px
  &:hover,
  &:focus
    border-color: $accent-active-color
  &::placeholder
    font-size: 13px

@mixin unit
  font-size: 13px
  margin-left: 5px

@mixin style
  color: $accent-color
  font-size: 14px

@mixin button
  height: 100%
  border: none
  background-color: transparent
  color: $accent-color
  font-size: 13px
  padding: 0 10px
  width: 100%
  &:hover
    cursor: pointer

@mixin before
  content: ''
  display: block
  width: 8px
  height: 8px
  transform: translateY(-50%)
  background-size: contain
  background-repeat: no-repeat
  position: absolute
  top: 50%

@mixin list
  &__active
    font-size: 14px
  &__dropdown
    width: calc(100% + 2px)
    position: absolute
    top: 100%
    left: -1px
    list-style: none
    border: 1px solid $text-black-color
    border-top: 0
    z-index: 2
  &__dropdown-item
    font-size: 14px
    background-color: #fff
    padding: 5px 10px
    border-top: 1px solid $border-color
    text-align: left
    &:hover
      background-color: $accent-light-color
      cursor: pointer

.toolbar
  max-height: 100px
  position: relative

  .params-row
    display: flex
    border-top: 1px solid $text-black-color

  .param-container
    background-color: #fff
    display: flex
    justify-content: center
    align-items: center
    padding: 5px 10px
    transition: .1s linear
    width: calc((100% - 200px - 130px) / 4)
    height: 32px
    &:hover,
    &.active
      background-color: $accent-light-color
    &.disabled
      &:hover
        background: #fff
        cursor: default
    &_weight,
    &_style,
    &_decoration,
    &_shadow,
    &_alignment,
    &_link,
    &_justification,
    &_group
      cursor: pointer
    &_default,
    &_link,
    &_group
      padding: 0
    &_mt
      padding-left: 15px
      position: relative
      &::before
        @include before
        background-image: url('../assets/images/toolbar/s-arrow-up.png')
        left: 7px
    &_mb
      padding-left: 15px
      position: relative
      &::before
        @include before
        background-image: url('../assets/images/toolbar/s-arrow-down.png')
        left: 7px
    &_family
      position: relative
      justify-content: flex-start
      &::before
        @include before
        background-image: url('../assets/images/toolbar/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        cursor: pointer
        &::before
          // background-image: url('../assets/images/toolbar/arrow-hover.png')
    &_height
      position: relative
      &::before
        @include before
        background-image: url('../assets/images/toolbar/s-arrow-vertical.png')
        left: 10px
    &_width
      position: relative
      &::before
        @include before
        background-image: url('../assets/images/toolbar/s-arrow-horizontal.png')
        left: 15px
        top: 45%
        transform: none
    &_width-selector
      font-size: 14px
      position: relative
      &::before
        @include before
        background-image: url('../assets/images/toolbar/arrow.png')
        top: 18.5px
        right: 7px
    &_fit
      position: relative
      &::before
        @include before
        background-image: url('../assets/images/toolbar/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        cursor: pointer
        &::before
          // background-image: url('../assets/images/toolbar/arrow-hover.png')
    &_list-style
      position: relative
      justify-content: flex-start
      &::before
        @include before
        background-image: url('../assets/images/toolbar/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        cursor: pointer
        &::before
          // background-image: url('../assets/images/toolbar/arrow-hover.png')
    &_list-color
      position: relative
      justify-content: flex-start
      &::before
        @include before
        background-image: url('../assets/images/toolbar/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        &::before
          // background-image: url('../assets/images/toolbar/arrow-hover.png')   
    &_list-background
      position: relative
      justify-content: flex-start
      &:hover
        cursor: pointer
      &::before
        @include before
        background-image: url('../assets/images/toolbar/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        &::before
          // background-image: url('../assets/images/toolbar/arrow-hover.png')
    &:not(:last-child)
      border-right: 1px solid $text-black-color

  .param-color
    &__A-container
      margin-right: 10px
      cursor: pointer
    &__A
      color: $accent-color
    &__color-box
      height: 2px
    &__input-hex
      @include input
      width: 60px
    &__input-hidden
      display: none

  .param-size
    &__input
      @include input
      width: 20px
    &__unit
      @include unit
  
  .param-weight
    &__B
      @include style

  .param-style
    &__I
      @include style

  .param-decoration
    &__U
      @include style

  .param-shadow
    &__S
      @include style
  
  .param-mt
    &__input
      @include input
      width: 20px
    &__unit
      @include unit

  .param-default
    &__button
      @include button

  .param-family
    @include list

  .param-line-height
    &__input
      @include input
      width: 20px
    &__unit
      @include unit

  .param-mb
    &__input
      @include input
      width: 20px
    &__unit
      @include unit
  
  .param-link
    &__button
      @include button

  .link-container
    width: 100%
    padding: 5px 10px
    background-color: #fff
    max-height: 33px
    &__input
      @include input
      width: 100%
      text-align: left
  
  .param-height
    &__input
      @include input
      width: 30px
    &__unit
      @include unit
  
  .param-width
    &__input
      @include input
      width: 35px
    &__100
      @include button
      width: calc(100% + 2px)
      position: absolute
      top: 100%
      left: -1px
      display: none
      background-color: #fff
      border: 1px solid $text-black-color
      border-top-color: $border-color
      &:hover
        background-color: $accent-light-color
    &__unit
      @include unit
  
  .param-fit
    @include list
    &__active,
    &__dropdown-item
      text-transform: capitalize
  .param-width-selector
    @include list

  .param-list-style
    @include list
  
  .param-list-color
    @include list
  
  .param-list-background
    @include list
      
  .template_text
    .param-container
      &:first-child,
      &:last-child
        width: 100px
      &:nth-child(2),
      &:nth-last-child(2)
        width: 65px


  .template_image
    .params-row
      &:nth-child(1)
        .param-container
          width: calc((100% - 240px) / 3)
      &:nth-child(2)
        .param-container
          width: calc((100% - 240px) / 2)
    .param-container
      &:first-child,
      &:last-child
        width: 120px !important
  
  .template_video
    .param-container
      width: calc(100% / 4)

  .template_list
    .param-container
      width: calc(100% / 2)

@media screen and (min-width: 1760px) 
  .toolbar
    .template_text
       .params-row
         .param-container
            &:first-child,
            &:last-child
              width: 100px
            &:nth-child(2),
            &:nth-last-child(2)
              width: 90px
            width: calc((100% - 200px - 180px) / 4)    
</style>