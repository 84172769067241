export default {
	data: () => ({
		saveFieldTimeout: null
	}),
	methods: {
		saveField(data, updateState) {
			if (this.saveFieldTimeout) {
				clearTimeout(this.saveFieldTimeout);
			}
			this.saveFieldTimeout = setTimeout(async () => {
				try {
					await this.$store.dispatch('updateField', { data, id: this.fieldId, updateState });

					if (this.$store.getters.activeBanner.attributes.isPublished) {
						this.$nextTick(async () => {
							const bannerNode = document.querySelector('.releasecat_banner');
							const html = await this.$store.dispatch('generateHtml', bannerNode)

							this.$store.dispatch('updateBanner', {
								id: this.$store.getters.activeBanner.id,
								data: {
									html,
									stream: this.$store.getters.activeStream.id
								}
							});
						});
					}
				} catch (e) {
					console.log(e)
					this.$toast.open({
						message: 'Error on save data',
						type: 'error'
					});
				}
			}, 300);
		}
	},
	created() {
		if (!this.fieldId) {
			this.$toast.open({
				message: 'Field id is required for save field mxixin',
				type: 'error'
			});
		}
	}
};