<template>
  <div
    :style="{
			...fieldStyles, 
			'width': 'inherit',
			'max-width': '100%'
		}"
  >
    <!-- Date -->
    <div v-if="field.attributes.type === 'date'" style="font-family: inherit;">
      {{ field.attributes.value | date }}
    </div>
    <!-- Header / Text -->
    <div v-else-if="field.attributes.type === 'header_1' 
			|| field.attributes.type === 'header_2'
			|| field.attributes.type === 'header_1_group'
			|| field.attributes.type === 'header_2_group'"
			style="font-family: inherit;"
		>
      {{ field.attributes.value }}
    </div>
		<div 
			v-else-if="field.attributes.type === 'text' || field.attributes.type === 'text_group'" 
			ref="textContainer"
			style="font-family: inherit;"
		></div>
		<!-- Html -->
		<div 
			v-else-if="field.attributes.type === 'html'" 
			style="font-family: inherit; white-space: normal;"
			ref="htmlContainer"
			v-html="field.attributes.value"
		></div>
    <!-- Image -->
    <img
      v-else-if="field.attributes.type === 'image' && field.attributes.image"
      :style="{'width': fieldStyles['width'], 'max-width': '100%', 'object-fit': 'inherit' }"
      :src="createImageURL(field.attributes.image)"
    >
    <!-- Video -->
    <iframe 
      v-else-if="field.attributes.type === 'youtube' && field.attributes.value.length"
      :src="field.attributes.value"
      frameborder="0" 
      allowfullscreen
			style="height: inherit; width: 100%;"
    ></iframe>
    <!-- List -->
    <ul 
			v-else-if="field.attributes.type === 'list' && field.attributes.value.length"
			style="font-size: inherit; padding-left: 20px; font-family: inherit;"
		>
      <li
        v-for="(line, index) in getBulletpointsLines()"
        :key="index"
				style="font-size: inherit; font-family: inherit;"
      >{{ line }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
		field: {
			type: Object,
			required: true
		}
	},
  methods: {
    createImageURL(image) {
      if (image.data?.attributes?.url) {
        return this.$config.STRAPI_HOST + image.data.attributes.url;
      } else if (image instanceof File) {
        return URL.createObjectURL(image);
      }
    },

    bindLinkStyle() {
      if (this.field.attributes.actionUrl.length) {
        return {
          "cursor": "pointer"
        }
      } else {
        return {
          "cursor": "default"
        }
      }
    },

    getBulletpointsLines() {
      return this.field.attributes.value.split('/nextbulletpoint');
    }
  },
  computed: {
    fieldStyles() {
      const styles = {
        ...this.field.attributes.styles,
        'margin-bottom': this.field.attributes.styles['margin-bottom'] + 'px',
        'margin-top': this.field.attributes.styles['margin-top'] + 'px'
      };
      if ('font-size' in styles) {
        styles['font-size'] = styles['font-size'] + 'px';
      }
      if ('height' in styles) {
        styles['height'] = styles['height'] === 'auto'
                           ? styles['height']
                           : styles['height'] + 'px';
      }
      if ('width' in styles) {
        styles['width'] = styles['width'] === 'auto'
                          || styles['width'] === '100%'
                          ? styles['width']
                          : styles['width'] + 'px';
      }
      if (this.field.attributes.actionUrl?.length) {
        styles['cursor'] = "pointer";
      }
      return styles;
    },
    image() {
      return this.field.image;
    }
  },
	watch: {
		'field.attributes': {
      handler(f) {
        if (f.type === 'text' || f.type === 'text_group') {
          const lines = f.value.split(/\r?\n/g);
          const html = lines.reduce((sum, line, index) => {
            return index === 0
                  ? sum + line
                  : sum + '<br/>' + line
          }, '');
           this.$nextTick(() => {
            this.$refs['textContainer'].innerHTML = html;
          });
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>