<template>
	<div class="statistics-modal">
		<h2 class="title">Statistics</h2>

		<form v-if="banner">
			<div class="section-container">
				<div class="block">
					<p><span class="key">Views:</span> <span class="value">{{ banner.attributes.views }}</span></p>
					<p><span class="key">Likes:</span> <span class="value">{{ banner.attributes.feedbackStats.good }}</span></p>
					<p><span class="key">Dislikes:</span> <span class="value">{{ banner.attributes.feedbackStats.bad }}</span></p>
				</div>
			</div>
		</form>

		<div class="buttons">
			<button 
				type="button"
				class="secondary cancel"
				@click="closeModal"
			>Close</button>
		</div>

	</div>
</template>

<script>
export default {
	name: 'StatisticsModal',
	props: {
		bannerId: {
			type: Number,
			required: true,
		}
	},
	data: () => ({
		banner: null
	}),
	async created() {
		this.banner = await this.$store.dispatch('getBanner', { 
			streamId: this.$store.getters.activeStream.id, 	
			bannerId: this.bannerId
		});
	},
	methods: {
		closeModal() {
			this.$emit('close');
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/_variables'
@import '../../assets/sass/_mixins'

.statistics-modal
	@include modal-layout
	.title
		@include modal-title	
	form
		@include modal-form
		.section-container
			.block
				margin-top: 10px
				border: 1px solid $border-color
				padding: 10px 20px
				p
					&:not(:first-child)
						margin-top: 5px
					.key
						width: 60px
						display: inline-block
						text-align: right
					.value
						color: $accent-active-color
						margin-left: 5px
	.buttons
		@include modal-buttons
</style>