<template>
	<div class="dashboard" :class="{ 'superadmin-padding': $store.getters.superadminPreviewUser }">
		<transition name="cover" mode="in-out">
			<div class="cover" v-if="isModaOpen && activeStream" @click="closeActiveModal"></div>
		</transition>

		<transition name="modal" mode="in-out">
			<template v-if="bannerModal.show">
				<BannerModal :mode="bannerModal.mode" :bannerId="bannerModal.id" @close="closeBannerModal" />
			</template>
			<template v-if="duplicateBannerModal.show">
				<DuplicateBannerModal :bannerId="duplicateBannerModal.id" @close="closeDuplicateBannerModal" />
			</template>
			<template v-if="statisticsModal.show">
				<StatisticsModal :bannerId="statisticsModal.id" @close="closeStatisticsModal" />
			</template>
			<template v-if="accountModal.show">
				<AccountModal @close="closeAccountModal" />
			</template>
			<template v-if="editStreamModal.show">
				<EditStreamModal @close="closeEditStreamModal" />
			</template>
		</transition>

		<div class="banners-container" v-if="activeStream" :class="{ 'show': bannerListModal.show }">
			<BannersList @newBanner="newBanner" @editBanner="editBanner" @close="closeBannerListModal" />
		</div>

		<transition name="fade" mode="out-in">
			<div class="editor-container" v-if="$store.getters.activeBanner">
				<FieldsEditor />
			</div>
		</transition>

		<transition name="fade" mode="out-in">
			<div class="quick-view-container" v-if="$store.getters.activeBanner">
				<BannerQuickView />
			</div>
		</transition>
	</div>
</template>

<script>
import BannersList from '@/components/BannersList';
import BannerModal from '@/components/modals/BannerModal';
import EditStreamModal from '@/components/modals/EditStreamModal.vue';
import DuplicateBannerModal from '@/components/modals/DuplicateBannerModal';
import StatisticsModal from '@/components/modals/StatisticsModal.vue';
import AccountModal from '@/components/modals/AccountModal.vue';
import FieldsEditor from '@/components/FieldsEditor';
import BannerQuickView from '@/components/BannerQuickView.vue';

export default {
	name: 'Dashboard',
	components: {
		EditStreamModal,
		BannersList,
		BannerModal,
		FieldsEditor,
		BannerQuickView,
		DuplicateBannerModal,
		StatisticsModal,
		AccountModal
	},
	data: () => ({
		editStreamModal: {
			show: false
		},
		duplicateBannerModal: {
			show: false,
			id: null
		},
		bannerModal: {
			show: false,
			mode: '',
			id: null
		},
		statisticsModal: {
			show: false,
			id: null
		},
		accountModal: {
			show: false
		},
		bannerListModal: {
			show: false
		},

		embedScriptElement: null,

		showBannerList: false
	}),
	computed: {
		user() {
			return this.$store.getters.user;
		},
		streams() {
			return this.$store.getters.streams;
		},
		activeStream() {
			return this.$store.getters.activeStream;
		},
		isModaOpen() {
			return this.bannerModal.show
				|| this.editStreamModal.show
				|| this.duplicateBannerModal.show
				|| this.statisticsModal.show
				|| this.accountModal.show
				|| this.bannerListModal.show;
		}
	},
	methods: {
		newBanner() {
			this.bannerModal = {
				show: true,
				mode: 'create',
				id: null
			}
		},
		editBanner(id) {
			this.bannerModal = {
				show: true,
				mode: 'edit',
				id
			}
		},
		closeBannerModal() {
			this.bannerModal = {
				show: false,
				mode: '',
				id: null
			}
		},

		showEditStreamModal() {
			this.editStreamModal.show = true;
		},
		closeEditStreamModal() {
			this.editStreamModal.show = false;
		},

		showDuplicateBannerModal(id) {
			this.duplicateBannerModal = {
				show: true,
				id
			}
		},
		closeDuplicateBannerModal() {
			this.duplicateBannerModal = {
				show: false,
				id: null
			}
		},

		showStatisticsModal(id) {
			this.statisticsModal = {
				show: true,
				id
			}
		},
		closeStatisticsModal() {
			this.statisticsModal = {
				show: false,
				id: null
			}
		},

		showAccountModal() {
			this.accountModal = {
				show: true
			}
		},
		closeAccountModal() {
			this.accountModal = {
				show: false
			}
		},

		showBannerListModal() {
			this.bannerListModal = {
				show: true
			}
		},
		closeBannerListModal() {
			this.bannerListModal = {
				show: false
			}
		},

		closeActiveModal() {
			this.closeBannerModal();
			this.closeDuplicateBannerModal();
			this.closeEditStreamModal();
			this.closeStatisticsModal();
			this.closeAccountModal();
			this.closeBannerListModal();
		},

		reloadEmbedScript() {
			if (this.embedScriptElement) {
				this.embedScriptElement.remove();
			}
			this.embedScriptElement = document.createElement('script');
			this.embedScriptElement.type = 'text/javascript';
			this.embedScriptElement.src = process.env.NODE_ENV === 'production'
				? 'https://releasecat.io/embed/index.js' : 'http://localhost:8000/embed/index.js';
			this.embedScriptElement.defer = true;
			document.body.appendChild(this.embedScriptElement);
		},


		confirmConversion() {
			gtag('event', 'conversion', { 'send_to': 'AW-10996802696/xH-gCJWD7eYDEIjJ1_so' });

			this.$store.dispatch('updateUser', {
				id: this.user.id,
				conversionConfirmed: true
			});
		}
	},
	created() {
		this.$eventBus.$on('showEditStreamModal', this.showEditStreamModal);
		this.$eventBus.$on('duplicateBanner', this.showDuplicateBannerModal)
		this.$eventBus.$on('showStatisticsModal', this.showStatisticsModal);
		this.$eventBus.$on('showAccountModal', this.showAccountModal);
		this.$eventBus.$on('showBannerListModal', this.showBannerListModal);

		if ('conversionConfirmed' in this.user && !this.user.conversionConfirmed) {
			this.confirmConversion();
		}
	},
	beforeDestroy() {
		this.$eventBus.$off('showEditStreamModal', this.showEditStreamModal);
		this.$eventBus.$off('duplicateBanner', this.showDuplicateBannerModal);
		this.$eventBus.$off('showStatisticsModal', this.showStatisticsModal);
		this.$eventBus.$off('showAccountModal', this.showAccountModal);
		this.$eventBus.$off('showBannerListModal', this.showBannerListModal);
	},
	watch: {
		'activeStream.id': {
			handler(id) {
				if (id) {
					this.$store.commit('setBanners', []);
					this.$store.dispatch('getBanners', id);
					window.releasecat = {
						id: this.$store.getters.activeStream.attributes.uid,
						showPopup: false,
						collectStats: false,
					};
					if (!this.embedScriptElement) {
						this.reloadEmbedScript();
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'
@import '../assets/sass/_mixins'

.dashboard
	height: calc(100vh - 50px)
	overflow: hidden
	position: relative
	padding: 30px
	padding-bottom: 0
	display: flex
	padding-left: 0
	&.superadmin-padding
		height: calc(100vh - 71px)

	.cover
		@include modal-cover

	.banners-container
		width: 310px
		height: 100%
		.open
			display: none

	.editor-container,
	.quick-view-container
		width: calc((100% - 310px) / 2)

	.editor-container
		overflow-y: scroll
		display: flex
		flex-direction: column

@media screen and (max-width: 1280px)
	.dashboard
		padding-top: 20px
		.editor-container
			flex: 1
		.quick-view-container
			width: calc(438px + 20px)

		.banners-container
			position: fixed
			border-right: 1px solid $border-color
			top: 0	
			right: 100%
			z-index: 99
			background: #fff
			transition: .2s ease-out
			box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 6px
			&.show
				right: calc(100% - 310px)

@media screen and (max-width: 1000px)
	.dashboard
		padding: 20px
		padding-bottom: 0
		.editor-container
			padding-right: 0
		.quick-view-container
			position: fixed
			left: 100%
//
.modal-enter, .modal-leave-to
	transform: translateX(100%)

.modal-enter-active, .modal-leave-active
	transition: all .3s ease
//
.cover-enter, .cover-leave-to
	opacity: 0

.cover-enter-active, .cover-leave-active
	transition: all .3s ease
//
.fade-enter, .fade-leave-to
	opacity: 0
	transform: scale(0.97)

.fade-enter-active, .fade-leave-active
	transition: all .3s ease
</style>