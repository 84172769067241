<template>
	<div class="banners-list">
		<div class="header_mobile">
			<button class="new" @click="newBanner">
				New post
			</button>
			<button class="cllose" @click="closeBannerList">
				Close
			</button>
		</div>

		<p class="section-title drafts">Drafts</p>		

		<div class="block new-container" :class="{ onboarding }">
			<button class="new" @click="newBanner">
				New post
			</button>
		</div>


		<template v-if="draftBanners.length">
			<div 
				class="block banner"
				v-for="banner in draftBanners"
				:key="banner.id"
				@click="setActiveBanner(banner)"
				:class="{ 'active': activeBanner && banner.id === activeBanner.id }"
			>
				<div class="data">
					<div class="header">
						<span class="release-date">{{ banner.attributes.releaseDate | date }}</span>
					</div>
					<div class="body">
						<span class="name">{{ banner.attributes.name }}</span>
					</div>
				</div>
				<transition name="expand">
					<div class="expand" v-if="activeBanner && activeBanner.id === banner.id">
						<button 
							class="edit"
							v-tooltip="'Edit post'"
							@click="editBanner(banner.id)"
						></button>
						<!-- <button class="appearance"></button> -->
						<button 
							class="duplicate"
							v-tooltip="'Duplicate post'"
							@click="duplicateBanner(banner.id)"
						></button>
					</div>
				</transition>
			</div>
		</template>

		<p class="section-title">Published</p>

		<template v-if="publishedBanners.length">
			<div 
				class="block banner"
				v-for="banner in publishedBanners"
				:key="banner.id"
				@click="setActiveBanner(banner)"
				:class="{ 'active': activeBanner && banner.id === activeBanner.id }"
			>
				<div class="data">
					<div class="header">
						<span class="release-date">{{ banner.attributes.releaseDate | date }}</span>
					</div>
					<div class="body">
						<span class="name">{{ banner.attributes.name }}</span>
					</div>
				</div>
				<transition name="expand" mode="out-in">
					<div class="expand" v-if="activeBanner && activeBanner.id === banner.id">
						<button 
							class="edit"
							v-tooltip="'Edit post'"
							@click.stop="editBanner(banner.id)"
						></button>
						<!-- <button class="appearance"></button> -->
						<button 
							class="duplicate"
							v-tooltip="'Duplicate post'"
							@click="duplicateBanner(banner.id)"
						></button>
						<button
							class="statistics"
							v-tooltip="'Statistics'"
							@click="showStatistics(banner.id)"
						></button>
					</div>
				</transition>
			</div>
		</template>
		<template v-else>
			<p class="no-banners">You haven't published any posts yet.</p>
		</template>
	</div>
</template>

<script>
export default {
	name: 'BannersList',
	data: () => ({
		draftBanners: [],
		publishedBanners: []
	}),
	computed: {
		banners() {
			return this.$store.getters.banners;
		},
		activeBanner() {
			return this.$store.getters.activeBanner;
		},
		onboarding() {
			return this.banners.length === 0;
		}
	},
	methods: {
		newBanner() {
			this.closeBannerList();
			this.$emit('newBanner');
		},

		setActiveBanner(banner) {
			if (this.activeBanner && banner.id === this.activeBanner.id) 
				return;
			this.$store.commit('setActiveBanner', null);
			setTimeout(() => {
				this.$store.commit('setActiveBanner', banner);
			})
		},

		editBanner(id) {
			this.closeBannerList();
			this.$emit('editBanner', id);
		},

		duplicateBanner(id) {
			this.closeBannerList();
			this.$eventBus.$emit('duplicateBanner', id);
		},

		showStatistics(id) {
			this.closeBannerList();
			this.$eventBus.$emit('showStatisticsModal', id);
		},

		sortBanners() {
			this.draftBanners = [];
			this.publishedBanners = [];

			const sortedBanners = this.$store.getters.sortedBanners;
			sortedBanners.forEach(banner => {
				if (banner.attributes.isPublished) {
					this.publishedBanners.push(banner);
				} else {
					this.draftBanners.push(banner);
				}
			});
		},

		closeBannerList() {
			this.$emit('close');
		}
	},

	watch: {
		banners: {
			handler() {
				this.sortBanners();
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'

.banners-list
	padding-right: 30px
	padding-left: 30px
	height: 100%
	overflow-y: scroll
	padding-bottom: 30px
	display: flex
	flex-direction: column
	.no-banners
		margin-top: 10px
		color: #bababa
		text-align: center
		font-size: 18px
	.header_mobile
		display: none
	.block
		border: 1px solid $border-color
		background: #fff
		&.onboarding
			animation-name: newPostOnboarding
			animation-duration: 1.5s
			animation-iteration-count: infinite
			box-shadow: 0 0 0 0 rgba($accent-color, .5)
			position: relative
			&:hover
				-webkit-animation: none
		&:hover
			border-color: $accent-active-color
		button.new
			width: 100%
			height: 60px
			font-size: 16px
			text-transform: uppercase
			color: $accent-color
			background: #fff
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
			display: flex
			align-items: center
			justify-content: center
			&::before
				content: ''
				display: block
				width: 12px
				height: 12px
				background: url('../assets/images/dashboard/icons/add.png') no-repeat center center
				margin-right: 10px
				cursor: pointer
			&:hover 
				cursor: pointer
				color: $accent-active-color
				&::before
					filter: $accent-active-color-filter

		&.banner
			cursor: pointer
			color: $text-black-color
			margin-top: 10px
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
			transition: background .2s linear
			&.active
				border-color: $text-black-color
				// background: $accent-light-color
				cursor: default
			.data
				padding: 20px
				.header
					display: flex
					align-items: center
					span
						font-weight: 700
						font-size: 13px
				.body
					margin-top: 8px
					font-size: 14px
					span
						display: inline-block
			.expand
				padding: 15px 20px
				border-top: 1px solid $text-black-color
				display: flex
				max-height: 50px
				button
					&.edit,
					&.duplicate,
					&.statistics
						display: block
						width: 16px
						height: 16px
						background-size: contain
						background-repeat: no-repeat
						&:hover
							filter: $accent-active-color-filter
							cursor: pointer
					&.edit
						background-image: url('../assets/images/dashboard/icons/edit.png')
					&.duplicate
						background-image: url('../assets/images/dashboard/icons/duplicate.png')
					&.statistics
						background-image: url('../assets/images/dashboard/icons/statistics.png')
						filter: $accent-color-filter
					&:not(:last-child)
						margin-right: 12px

	.section-title
		display: inline-block
		margin: 0 auto
		text-transform: uppercase
		font-weight: 600
		margin: 15px auto 5px
		font-size: 13px
		color: #707070
		&.drafts
			margin-top: 0
			margin-bottom: 15px

@media screen and (max-width: 1280px)
	.banners-list
		padding: 20px
		padding-top: 0
		.header_mobile
			display: flex
			justify-content: space-between
			align-items: center
			border-bottom: 1px solid $border-color
			min-height: 60px
			margin-bottom: 15px
			button
				color: $accent-color
				text-transform: uppercase
				font-size: 15px
				transition: .1s linear
				&:hover
					cursor: pointer
					color: $accent-active-color
		.section-title
			&.drafts
				margin-bottom: 5px
		.new-container
			display: none
// .expand-enter, .expand-leave-to

// .expand-enter-active, .expand-leave-active
// 	transition: all .2s ease

@keyframes newPostOnboarding
	70%
		box-shadow: 0 0 0 10px rgba($accent-color, 0)
	100%
		box-shadow: 0 0 0 0 rgba($accent-color, 0)
</style>