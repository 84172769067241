<template>
	<div class="quick-view" v-if="fieldsGroups.length">
		<div class="banner-container" :style="{ 'width': parseInt( bannerStyles['width'].replace('px', '') ) + 2 + 'px' }">
			<div
				:style="{ ...bannerStyles, 'padding-left': 0, 'padding-right': 0, 'overflow': 'hidden' }"
				ref="banner"
				class="releasecat_banner"
			>
				<FieldsGroupValue
					v-for="fieldsGroup in fieldsGroups"
					:key="fieldsGroup[0].id"	
					:fieldsGroup="fieldsGroup"
					:bannerStyles="bannerStyles"
				/>

				<div 
					class="feedback" 
					v-if="activeStream.attributes.feedback"
					:style="{ 
						'padding-left': bannerStyles['padding-left'],
						'padding-right': bannerStyles['padding-right'],
						'margin-top': 10 + 'px'
					}"
				> 
					<img 
						class="feedback__icon"
						v-for="(icon, index) in ['Good', 'Bad']"
						:key="index"
						:src="getFeedbackIconUrl(icon)"
						@click="activeFeedback = icon"
						:style="{
							'filter': activeFeedback && activeFeedback === icon ? 'grayscale(0%)' : 'grayscale(100%)'
						}"
					>
				</div>
			</div>
		</div>

		<div class="manage-panel" :style="{ 'width': bannerStyles['width'] }">
			<button
				class="preview"
				@click="previewPopup"
			>Preview Popup</button>
			<button
				class="preview"
				@click="previewSidePanel"
			>Preview Side Panel</button>
			<button 
				class="publish"
				@click="publishBanner"
				v-if="!isPublished"
			>Publish</button>
			<button
				class="unpublish"
				@click="unpublishBanner"
				v-else
			>Unpublish</button>
		</div>
	</div>	
</template>

<script>
import FieldsGroupValue from '@/components/FieldsGroupValue';

export default {
	name: 'BannerPreview',
	components: {
		FieldsGroupValue
	},
	data: () => ({
		activeFeedback: null
	}),
	computed: {
		activeStream() {
			return this.$store.getters.activeStream;
		},
		activeBanner() {
			return this.$store.getters.activeBanner;
		},
		isPublished() {
			return this.activeBanner.attributes.isPublished;
		},
		fields() {
			return this.$store.getters.fields;
		},
		bannerStyles() {
			return {
				'width': `${this.activeBanner.attributes.styles.width}px`,
				'padding-top': `${this.activeBanner.attributes.styles['padding-top']}px`,
				'padding-bottom': `${this.activeBanner.attributes.styles['padding-bottom']}px`,
				'padding-left': `${this.activeBanner.attributes.styles['padding-left']}px`,
				'padding-right': `${this.activeBanner.attributes.styles['padding-right']}px`,
				'background': `${this.activeBanner.attributes.styles.background}`
			}
		},
		fieldsGroups() {
			const fieldsGroups = [];
			this.fields.forEach(field => {
				if (field.attributes.type === 'combo') {
					const relatedComboFields = this.fields.filter(f => f.attributes.relatedGroupId === field.id);
					fieldsGroups.push([field, ...relatedComboFields]);
				} else if (!field.attributes.relatedGroupId) {
					fieldsGroups.push([field]);
				}
			})
			return fieldsGroups;
		},
		feedbackIcons() {
			return this.$store.getters.feedbackIcons;
		}
	},
	methods: {
		getFeedbackIconUrl(iconType) {
			const icon = this.feedbackIcons.find(icon => icon.attributes.name === `feedbackIcon${iconType}`);
			return this.$config.STRAPI_HOST + icon.attributes.image.data.attributes.url;
		},

		async publishBanner() {
			this.$store.dispatch('generateHtml', this.$refs.banner)
				.then(html => {
					this.$store.dispatch('updateBanner', {
						data: {
							html,
							isPublished: true,
							stream: this.activeStream.id
						},
						id: this.activeBanner.id
					}).then((banner) => {
						this.$store.commit('setActiveBanner', banner);
					}).catch(e => {
						this.$toast.error('Error on update banner');
					}) 
				});

			this.$store.dispatch('updateBanner', {
				id: this.$store.getters.activeBanner.id,
				data: {
					releaseDate: this.$store.getters.currentDate,
					stream: this.$store.getters.activeStream.id
				}
			});
		},

		unpublishBanner() {
			this.$store.dispatch('updateBanner', {
				data: {
					html: '',
					isPublished: false,
					stream: this.$store.getters.activeStream.id
				},
				id: this.activeBanner.id
			}).then((banner) => {
				this.$store.commit('setActiveBanner', banner);
			});
		},

		async previewPopup() {
			const html = await this.$store.dispatch('generateHtml', this.$refs.banner);
			const activeBanner = Object.assign({}, this.activeBanner.attributes, { html });
			Releasecat.showPopup('force', activeBanner);
		},

		async previewSidePanel() {
			if (!this.activeBanner.attributes.isPublished) {
				const html = await this.$store.dispatch('generateHtml', this.$refs.banner);
				const activeBanner = Object.assign({}, this.activeBanner.attributes, { html, id: this.activeBanner.id });
				Releasecat.showSidePanel(activeBanner);
			} else {
				Releasecat.showSidePanel();
			}
		}
	},
	created() {
		this.$eventBus.$on('previewPopup', this.previewPopup);
		this.$eventBus.$on('previewSidePanel', this.previewSidePanel);
		this.$eventBus.$on('publishBanner', this.publishBanner);
		this.$eventBus.$on('unpublishBanner', this.unpublishBanner);
	},
	beforeDestroy() {
		this.$eventBus.$off('previewPopup', this.previewPopup);
		this.$eventBus.$off('previewSidePanel', this.previewSidePanel);
		this.$eventBus.$off('publishBanner', this.publishBanner);
		this.$eventBus.$off('unpublishBanner', this.unpublishBanner);
	}
}

</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'

.quick-view
	height: 100%
	overflow-y: scroll
	width: 100%
	padding-bottom: 30px
	.manage-panel
		display: flex
		justify-content: center
		width: 100%
		margin: 0 auto
		margin-top: 40px
		button
			text-transform: uppercase
			color: $accent-color
			cursor: pointer
			font-size: 15px
			&:not(:last-child)
				margin-right: 20px
			&:hover
				color: $accent-active-color
	.banner-container
		border: 1px solid #d7d7d7
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
		margin: 0 auto

		.releasecat_banner
			.feedback
				display: flex
				justify-content: flex-end
				.feedback__icon
					width: 34px
					height: 34px
					cursor: pointer
					transition: transform .1s linear
					margin-left: 10px
					&:hover
						transform: scale(1.1)
</style>