<template lang="html">
  <!-- Date -->
  <div 
    class="input-container" 
    v-if="field.type === 'date'"
  >
    <input
      class="input input-date"
      type="date"
      :value="field.value"
      @input="setValue($event.target.value)"
      required="required"
    >
  </div>
  <!-- Header -->
  <div
    class="input-container"
    v-else-if="
      field.type === 'header_1' 
      || field.type === 'header_2' 
      || field.type === 'header_1_group' 
      || field.type === 'header_2_group'"
  >
    <input
      class="input input-text"
      type="text"
      :value="field.value"
      @input="setValue($event.target.value)"
      ref="inputHeader"
      :placeholder="`Header ${field.type === 'header_1' || field.type === 'header_1_group' ? '1' : '2'}`"
    >
  </div>
  <!-- Text -->
  <div
    class="input-container"
    v-else-if="field.type === 'text' || field.type === 'text_group'"
  >
    <textarea
      class="input input-textarea"
      ref="inputTextarea"
      rows="1"
      placeholder="Text"
      :value="field.value"
      @input="setValue($event.target.value)"
			spellcheck="false"
    ></textarea>
  </div>
	<!-- Html -->
	<div class="html-container" v-else-if="field.type === 'html'">
		<div 
			ref="htmlEditor"
			class="html-editor"
			:class="{ 'active': activeField && fieldId === activeField.id }"
		></div>
	</div>
  <!-- Image -->
  <div 
    class="input-container input-container_image"
    v-else-if="field.type === 'image'"
  >
    <img
      class="image"
      v-if="hasFieldImage"
      :src="createImageURL(field.image)"
    >
    <button 
      type="button"
      class="input-button"
      @click="$refs['inputImage'].click()"
    >
      {{ hasFieldImage ? 'Change' : 'Add' }}
    </button>
    <input
      class="input-image"
      type="file"
      accept="image/*"
      @change="onImageChange"
      ref="inputImage"
    >
  </div>
  <!-- Video (Youtube) -->
  <div
    class="input-container"
    v-else-if="field.type === 'youtube'"
  >
    <input
      class="input inputVideo"
      type="text"
      placeholder="Enter youtube link and press 'Enter'"
      ref="inputVideo"
      :value="field.value"
      @change="setYoutubeUrl($event.target.value)"
    >
  </div>
  <!-- List -->
  <div
    class="input-container"
    v-else-if="field.type === 'list'"
  >
    <ul class="input-bp__list">
      <li
        class="input-bp__line"
        v-for="(line, index) in bpLines"
        :key="index"
      >	
        <span class="input-bp__bulletpoint"></span>
        <input
          class="input-bp__input"
          type="text"
          v-model="bpLines[index]"
          @keydown="bpHandler"
          :ref="`line-${index}`"
          :name="`line-${index}`"
        >
      </li>
    </ul>
  </div>
  <!-- Combo -->
  <div 
    class="input-container input-container_combo"
    v-else-if="field.type === 'combo'"
  >
    <img
      class="image_combo"
      :src="field.value ? this.$config.STRAPI_HOST + field.value : createImageURL(field.image)"
    >
    <span class="combo-value">{{ getComboValue(field.id) }}</span>
  </div>
</template>

<script>
import SaveFieldMixin from "@/mixins/saveField";
import loader from "@monaco-editor/loader";
import copy from 'copy-to-clipboard';

export default {
	props: {
		fieldId: {
			type: Number,
			required: true,
		},
	},
	mixins: [SaveFieldMixin],
	data: () => ({
		hasFieldImage: false,
		bpLines: [""],
		fieldStyles: "",
		fieldOptions: {},

		htmlEditor: null,
		isControlPressed: false,
		resizeTimeout: null,

		htmlFilePlaceholder: `<div class="post">
	<!-- Type HTML code below -->
	
</div>

<style>
/* Use pefix .post for selectors */
.post

</style>`
	}),
	computed: {
		fields() {
			return this.$store.getters.fields;
		},
		field() {
			return this.$store.getters.field(this.fieldId).attributes;
		},
		activeField() {
			return this.$store.getters.activeField;
		},
		fieldImage() {
			return this.field.image;
		},
		activeBanner() {
			return this.$store.getters.activeBanner;
		},
	},
	methods: {
		async setValue(value) {
			this.$store.commit("setField", {
				id: this.fieldId,
				data: { value },
			});
			this.saveField({ value, banner: this.activeBanner.id }, false);
		},

		setImage(image) {
			this.$store.commit("setField", {
				id: this.fieldId,
				data: { image },
			});
			this.hasFieldImage = true;

			this.saveField({ image, banner: this.activeBanner.id }, true);
		},
		// IMAGE
		createImageURL(image) {
			if (image.data?.attributes?.url) {
				return this.$config.STRAPI_HOST + image.data.attributes.url;
			} else if (image instanceof File) {
				return URL.createObjectURL(image);
			}
		},

		async onImageChange(e) {
			const targetImage = e.target.files[0];

			if (targetImage.size > 1048576) {
				this.$refs["inputImage"].value = "";
				alert("Maximum image size - 1MB");
				return;
			}

			const imageToDelete = this.field.image;

			if (imageToDelete && imageToDelete.data?.id) {
				this.$store.dispatch("deleteImage", imageToDelete.data.id);
			}

			this.setImage(targetImage);
			this.$forceUpdate();
		},

		// VIDEO
		setYoutubeUrl(link) {
			if (link.startsWith("https://youtu.be/")) {
				const videoId = link.slice(17);
				link = "https://www.youtube.com/embed/" + videoId;
				this.setValue(link);
			}
			if (link.includes("youtube.com/embed/")) {
				this.setValue(link);
			} else if (link.includes("youtube.com/watch?v=")) {
				// from link?v=id get id
				const videoQuery = link.split("?")[1];
				const videoId = videoQuery
					.split("&")
					.find((query) => query.startsWith("v="))
					.slice("2");

				link = "https://www.youtube.com/embed/" + videoId;
				this.setValue(link);
			} else if (link !== "") {
				this.setValue("");
				this.$refs["inputVideo"].value = "";
				alert("Incorrect YouTube link format");
			}
		},

		// List (bp - bulletpoints)
		bpHandler(e) {
			// ENTER
			if (e.keyCode === 13) {
				// get cuurent line index
				const lineIndex = e.target.attributes.name.value.split("-")[1];
				// next line index
				const nextLineIndex = +lineIndex + 1;
				// add new line
				const bpLinesCopy = this.bpLines;
				bpLinesCopy.splice(nextLineIndex, 0, "");
				this.bpLines = bpLinesCopy;
				// focus last line
				this.$nextTick(() => {
					this.$refs[`line-${nextLineIndex}`][0].focus();
				});
			}
			// BACKSPACE
			else if (
				e.keyCode === 8 &&
				e.target.value === "" &&
				this.bpLines.length > 1
			) {
				// prevent backspace navigation
				e.preventDefault();
				// get current line index
				const lineIndex = e.target.attributes.name.value.split("-")[1];
				// delete line
				this.bpLines.splice(lineIndex, 1);
				// focus previous line
				this.$nextTick(() => {
					if (lineIndex == 0 && this.bpLines.length > 0) {
						this.$refs["line-0"][0].focus();
					} else {
						const prevLineIndex = +lineIndex - 1;
						this.$refs[`line-${prevLineIndex}`][0].focus();
					}
				});
			}
			// ARROW UP
			else if (e.keyCode === 38) {
				// get current line index
				const lineIndex = e.target.attributes.name.value.split("-")[1];
				// focus prev line
				if (lineIndex > 0) {
					const prevLineIndex = +lineIndex - 1;
					this.$refs[`line-${prevLineIndex}`][0].focus();
				}
			}
			// ARROW DOWN
			else if (e.keyCode === 40) {
				// get current line index
				const lineIndex = e.target.attributes.name.value.split("-")[1];
				// focus next line
				if (lineIndex < this.bpLines.length - 1) {
					const nextLineIndex = +lineIndex + 1;
					this.$refs[`line-${nextLineIndex}`][0].focus();
				}
			}
		},

		getComboValue(listFieldId) {
			const relatedH2Field = this.fields.find(
				(f) => f.related_group_id && f.related_group_id === listFieldId
			);
			if (relatedH2Field && relatedH2Field.value) {
				return relatedH2Field.value;
			} else {
				return "Combo";
			}
		},

		updateComboImageInput(fieldId) {
			if (this.fieldId === fieldId) {
				this.$forceUpdate();
			}
		},

		onWindowResize() {
			if (this.field.type === 'html' && this.htmlEditor) {
				if (this.resizeTimeout) {
					clearTimeout(this.resizeTimeout);
				}
				this.resizeTimeout = setTimeout(() => {
					console.log('resize');
					this.htmlEditor.layout();
				}, 1000);
			}
		},

		// Init methods
		autoFocus() {
			if (
				this.field.type === "header_1" ||
				this.field.type === "header_2" ||
				this.field.type === "header_1_group" ||
				this.field.type === "header_2_group"
			) {
				setTimeout(() => {
					this.$refs["inputHeader"].focus();
				});
			} else if (
				this.field.type === "text" ||
				this.field.type === "text_group"
			) {
				setTimeout(() => {
					this.$refs["inputTextarea"].focus();
				});
			} else if (this.field.type === "image") {
				setTimeout(() => {
					this.$refs["inputImage"].click();
				});
			} else if (this.field.type === "youtube") {
				setTimeout(() => {
					this.$refs["inputVideo"].focus();
				});
			} else if (this.field.type === "list") {
				setTimeout(() => {
					this.$refs["line-0"][0].focus();
				});
			} else if (this.field.type === 'html') {
				setTimeout(() => {
					loader.init().then(() => {
						this.htmlEditor.focus()
					});
				})
			}

			this.$store.commit("setField", {
				id: this.fieldId,
				options: {
					focus: false,
				},
			});
		},

		initBulletpoints() {
			this.bpLines = this.field.value.split("/nextbulletpoint");
			this.$watch(
				() => this.bpLines,
				(bpLs) => {
					this.setValue(bpLs.join("/nextbulletpoint"));
				}
			);
			this.$watch(
				() => this.field.value,
				(value) => {
					this.bpLines = value.split("/nextbulletpoint");
				}
			);
		},

		initHtmlEditor() {
			loader.init().then((monaco) => {
				const editorOptions = {
					language: "html",
					minimap: { enabled: false },
					lineNumbers: 'on',
					fontSize: 14,
					contextmenu: false,
					scrollbar: {
						vertical: 'hidden',
						horizontal: 'hidden',
					},
					scrollBeyondLastLine: false,
					value: this.field.value ? this.field.value : this.htmlFilePlaceholder,
				};

				monaco.editor.defineTheme('fieldDefault', {
					base: 'vs',
					inherit: true,
					rules: [{ background: 'ffffff' }],
					colors: {
						'editor.background': '#ffffff',
						'editor.foreground': '#41494d',
						'editor.lineHighlightBackground': '#fff',
						'editorLineNumber.foreground': '#41494d',
						'editorLineNumber.activeForeground': '#cc7586',
					}
				});
				monaco.editor.setTheme('fieldDefault');

				this.htmlEditor = monaco.editor.create(this.$refs.htmlEditor, editorOptions);

				this.htmlEditor.onDidBlurEditorWidget(() => {
					this.isControlPressed = false;
				});

				// Keys handlers
				// Enter:
				this.htmlEditor.addCommand(monaco.KeyCode.Enter, () => {
					// only fired when popup invisible
					this.appendHtmlEditorHeight(true);
					this.htmlEditor.trigger('keyboard', 'type', { text: '\n' });
				}, 'editorTextFocus && !suggestWidgetVisible && !renameInputVisible && !inSnippetMode && !quickFixWidgetVisible');


				this.htmlEditor.onKeyDown((e) => {
					if (e.code === 'ControlLeft' || e.code === 'ControlRight' || e.code === 'OsLeft' || e.code === 'OSRight') {
						this.isControlPressed = true;
					}
					if (e.code === 'KeyC' && this.isControlPressed) {
						e.preventDefault();
						const selectedText = this.htmlEditor.getModel().getValueInRange(this.htmlEditor.getSelection());
						copy(selectedText);
					}
					if (e.code === 'Backspace' || e.code === 'KeyV') {
						setTimeout(() => {
							this.appendHtmlEditorHeight();
						}, 50);
					}
				});

				this.htmlEditor.onKeyUp((e) => {
					if (e.code === 'ControlLeft' || e.code === 'ControlRight' || e.code === 'OSLeft' || e.code === 'OSRight') {
						this.isControlPressed = false;
					}
				});

				// On fold
				this.htmlEditor.onMouseDown((e) => {
					// this.appendHtmlEditorHeight();
					if (e.target.element.closest('.cldr')) {
						setTimeout(() => {
							this.appendHtmlEditorHeight();
						}, 150);
					}
				});
				// Watch for changes
				this.htmlEditor.onDidChangeModelContent((e) => { 
					this.setValue(this.htmlEditor.getValue());
				});
				this.appendHtmlEditorHeight();
			});
		},

		appendHtmlEditorHeight(newLine = false) {
			const linesCount = this.htmlEditor._modelData.viewModel.getLineCount() + (newLine ? 1 : 0);
			const lineHeight = this.getOS() === 'windows' ? 19 : 21;
			this.$refs.htmlEditor.style.height = `${linesCount * lineHeight}px`;
			this.$nextTick(() => {
				this.htmlEditor.layout();
				this.htmlEditor.setScrollPosition({scrollTop: 0});
			});
		},
		
		getOS() {
			return navigator.platform.indexOf('Win') > -1 ? 'windows' : 'mac';
		}
	},
	created() {
		this.$eventBus.$on('windowResize', this.onWindowResize);
		this.$eventBus.$on("updateComboImageInput", this.updateComboImageInput);
	},
	mounted() {
		// Autofocus on add new field
		if (this.field.type === "image") {
			if (this.field.image && this.field.image.data) {
				this.hasFieldImage = true;
			}
		}

		else if (this.field.type === "text" || this.field.type === "text_group") {
			this.$nextTick(() => {
				this.$autosize(this.$refs["inputTextarea"]);
			});
		}

		else if (this.field.type === "html") {
			this.initHtmlEditor();
		} 
		
		else if (this.field.type === "list") {
			this.initBulletpoints();
		} 
		
		else if (this.field.type === "video") {
			this.$refs["inputVideo"].value = this.field.value;
		}

		const options = this.$store.getters.field(this.fieldId).options;
		if (options && options.focus) {
			this.autoFocus();
		}
	},
	beforeDestroy() {
		this.$eventBus.$off("updateComboImageInput", this.updateComboImageInput);
		this.$eventBus.$off('windowResize', this.onWindowResize);
	},
	watch: {
		youtubeLink() {
			this.setUrl();
		}
	},
};
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'

.input-container
	flex: 1
	&_image,
	&_combo
		display: flex
		align-items: center
		position: relative
	.input
		color: $text-black-color
		font-size: 14px
		line-height: 1.3em
		background-color: transparent
		border: none
		border-bottom: 1px solid transparent
		transition: .1s linear
		width: 100%
		padding: 5px 0
		&:hover
			border-color: $accent-color
		&:focus
			border-color: $accent-active-color
	.input-date
		&::-webkit-inner-spin-button
			-webkit-appearance: none
			display: none
		&::-webkit-calendar-picker-indicator
			background-image: url('../assets/images/dashboard/icons/calendar.png')
			&:hover
				cursor: pointer
				filter: $accent-active-color-filter
	.input-textarea
		resize: none
	.input-image
		display: none
	.image
		height: 29px
		width: 100px
		margin-right: 15px
		object-fit: cover
	.image_combo
		height: 40px
		position: absolute
		left: 0
		max-width: 45px
		object-fit: contain
	.combo-value
		margin-left: 55px
		color: $text-black-color
		font-size: 14px
		line-height: 1.3em
	.input-button
		border: none
		background: none
		color: $accent-color
		text-transform: uppercase
		transition: .1s linear
		font-size: 14px
		&:hover
			cursor: pointer
			color: $accent-active-color
	.input-bp
		&__list
			list-style: none
			width: 100%
			&:hover
				.input-bp__line
					.input-bp__input
						border-color: $accent-color
		&__line
			position: relative
			&:last-child
				.input-bp__input
					padding-bottom: 5px
					border-bottom: 1px solid transparent
					transition: border-color .1s linear
		&__bulletpoint
			display: block
			width: 4px
			height: 4px
			background-color: $text-black-color
			border-radius: 100%
			position: absolute
			top: 9px
		&__input
			background: transparent
			border: none
			width: 100%

			font-size: 14px
			color: $text-black-color
			padding-left: 10px
			&:focus
				border-color: $accent-active-color !important

.html-container
	width: calc(100% - 90px)
	.html-editor
		width: 100%
		height: 21px
	// &.active
	// 	border-bottom: 1px solid $accent-color !important

// @media screen and (max-width: 680px)
//   .input-container
//     .input-text,
//     .input-textarea,
//     .input-date
//       border-radius: 0
//       font-size: 14px
</style>

<style lang="sass">
// .margin
// 	width: 20px !important
// 	.margin-view-overlays
// 		width: 100% !important
// 		&>div
// 			width: 100% !important
// 			.current-line
// 				width: 100% !important
// 			.line-numbers
// 				width: 100% !important

.monaco-editor
	width: 100% !important
	.overflow-guard
		width: 100% !important
		.monaco-scrollable-element
			// left: 30px !important
			width: 100% !important
</style>
