<template>
	<div 
		:style="{ ...styles, 'display': 'flex' }"
	>
		<img
			v-if="fieldsGroup[0].attributes.type === 'combo'"
			:src="getComboIconURL(fieldsGroup[0])"
			style="width: 64px; height: 64px; margin-right: 10px;"
		>
		<div style="flex: 1; max-width: 100%">
			<div 
				v-for="field in fieldsGroup.filter(f => f.attributes.type !== 'combo')"
				:key="field.id"
			>
				<template v-if="field.attributes.actionUrl && field.attributes.actionUrl.length">
					<a style="text-decoration: none;" :href="field.attributes.actionUrl" target="_blank">
						<FieldValue
							:field="field"
						/>
					</a>
				</template>
				<template v-else>
					<FieldValue
						:field="field"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import FieldValue from '@/components/FieldValue'

export default {
	name: 'FieldsGroupValue',
	props: {
		fieldsGroup: {
			type: Array,
			required: true
		},
		bannerStyles: {
			type: Object,
			required: true
		}
	},
	components: {
		FieldValue
	},
	computed: {
		styles() {
			return {
				'background-color': this.fieldsGroup[0].attributes.type === 'combo' 
					? this.fieldsGroup[0].attributes.styles['background-color'] : 'transparent',
				'padding-top': this.fieldsGroup[0].attributes.type === 'combo' ? '15px' : '0',
				'padding-bottom': this.fieldsGroup[0].attributes.type === 'combo' ? '15px' : '0',
				'padding-left': this.bannerStyles['padding-left'],
				'padding-right': this.bannerStyles['padding-right']
			}
		}
	},
	methods: {
		getComboIconURL(comboField) {
			if (comboField.attributes.value.length) {
				return this.$config.STRAPI_HOST + comboField.attributes.value;
			} 
			else if (comboField.attributes.image instanceof File) {
				return URL.createObjectURL(comboField.attributes.image);
			}
			else if (comboField.attributes.image?.data.attributes?.url) {
				return this.$config.STRAPI_HOST + comboField.attributes.image.data.attributes.url;
			}
		}
	}
}
</script>