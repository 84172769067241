<template>
	<div class="dublicate-banner-modal">
		<h2 class="title">Duplicate post</h2>

		<form @submit.prevent="duplicateBanner">
			<div class="input-container">
				<label for="name">Enter post name:</label>
				<input type="text" id="name" v-model="banner.name" ref="inputName">
			</div>

			<div class="loading" v-if="isLoading"></div>
			<div class="buttons" v-else>
				<button 
					type="button"
					class="secondary cancel"
					@click="closeModal"
				>Cancel</button>
				<button 
					type="submit"
					class="main save"
				>Duplicate</button>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: 'DuplicateBannerModal',
	props: {
		bannerId: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		isLoading: false,
		banner: {
			name: '',
			releaseDate: ''
		}
	}),
	methods: {
		closeModal() {
			this.$emit('close');
		},

		validateBanner() {
			if (this.banner.name.length === 0)
				throw 'Banner name is required';
			else if (this.banner.name.length < 3)
				throw 'Banner name must be at least 3 characters long';
			else if (this.banner.name.length > 100)
				throw 'Banner name must be less than 100 characters long';
			else if (this.banner.releaseDate.length === 0)
				throw 'Release date is required';	

			return true;
		},

		async duplicateBanner() {
			try {
				this.isLoading = true;
				if (this.validateBanner()) {
					const fields = await this.$store.dispatch('getFields', this.bannerId);
					fields.sort((a, b) => a.attributes.index - b.index);

					const banner = this.$store.getters.banner(this.bannerId);

					const duplicatedBanner = await this.$store.dispatch('createBanner', {
						name: this.banner.name,
						releaseDate: this.banner.releaseDate,
						isPublished: false,
						html: '',
						stream: this.$store.getters.activeStream.id,
						styles: banner.attributes.styles,
						feedbackStats: {
							good: 0,
							bad: 0
						},
						views: 0
					});

					let relatedGroupId = null;
					for (let i = 0; i < fields.length; i++) {
						if (!fields[i].attributes.relatedGroupId) {
							relatedGroupId = null;
						}

						const fieldData = {
							...fields[i].attributes,
							banner: duplicatedBanner.id,
							relatedGroupId: relatedGroupId ? relatedGroupId : null
						}

						if ( (fields[i].attributes.type === 'image' || fields[i].attributes.type === 'combo' ) 
							&& fields[i].attributes.image.data 
							&& fields[i].attributes.image.data.attributes) 
						{
							const imageData = fields[i].attributes.image.data.attributes;
							const imageUrl = this.$config.STRAPI_HOST + imageData.url;
							const imageFile = await this.$store.dispatch('createImageFile', {
								url: imageUrl,
								name: imageData.name,
								mime: imageData.mime,
							});
							fieldData.image = imageFile;
						}

						const field = await this.$store.dispatch('createField', {
							fieldData,
							updateState: false
						});

						if (fields[i].attributes.type === 'combo') {
							relatedGroupId = field.id;
						}
					}

					this.$store.commit('setActiveBanner', duplicatedBanner);

					this.$emit('close');
				}
			} catch (e) {
				const message = e.response?.data.error.message || e;
				this.$toast.open({
					message,
					type: 'error'
				});
			} finally {
				this.isLoading = false;
			}
		}
	},
	mounted() {
		this.$refs.inputName.focus();
		this.banner.releaseDate = this.$store.getters.currentDate;
	}
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/_variables'
@import '../../assets/sass/_mixins'

.dublicate-banner-modal
	@include modal-layout
	.title
		@include modal-title
	form
		@include modal-form
	.loading
		@include modal-loading
	.buttons
		@include modal-buttons

	input[type="date"]
		&::-webkit-inner-spin-button 
			-webkit-appearance: none
			display: none
		&::-webkit-calendar-picker-indicator
			background-image: url('../../assets/images/dashboard/icons/calendar.png')
			&:hover
				cursor: pointer
				filter: $accent-active-color-filter
</style>