<template>
	<div class="banner-modal">
		<h2 class="title">{{ mode === 'create' ? 'New post' : 'Edit post' }}</h2>

		<form @submit.prevent="saveBanner">
			<div class="input-container">
				<label for="name">Enter post name:</label>
				<input type="text" id="name" v-model="banner.name" ref="inputName">
			</div>

			<div class="loading" v-if="isLoading"></div>
			<div class="buttons" v-else>
				<button 
					type="button"
					class="secondary cancel"
					@click="closeModal"
				>Cancel</button>
				<button 
					type="submit"
					class="main save"
				>{{ mode === 'create' ? 'Add' : 'Save' }}</button>
			</div>
		</form>

		<button 
			v-if="mode === 'edit' && !isLoading"
			type="button"
			class="delete"
			@click="deleteBanner"
		>Delete post</button>
	</div>
</template>

<script>
export default {
	name: 'BannerModal',
	props: {
		mode: {
			type: String,
			required: true
		},
		bannerId: {
			type: Number | null
		}
	},
	data: () => ({
		isLoading: false,
		banner: {
			name: '',
			releaseDate: ''
		}
	}),
	methods: {
		closeModal() {
			this.$emit('close');
		},

		async saveBanner() {
			try {
				this.isLoading = true;
				if (this.validateBanner()) {
					const bannerData = Object.assign({}, this.banner);
					bannerData.stream = this.$store.getters.activeStream.id;

					if (this.mode === 'create') {
						const banner = await this.createBanner(bannerData);

						this.$toast.open({
							message: 'Post created',
							type: 'success'
						});

						this.$store.commit('setActiveBanner', banner);
					} 

					else if (this.mode === 'edit') {
						await this.updateBanner(bannerData);

						this.$toast.open({
							message: 'Banner updated',
							type: 'success'
						});

						setTimeout(() => {
							if (this.$store.getters.activeBanner.attributes.isPublished) {
								this.$nextTick(async () => {
									const bannerNode = document.querySelector('.releasecat_banner');
									const html = await this.$store.dispatch('generateHtml', bannerNode)
		
									this.$store.dispatch('updateBanner', {
										id: this.$store.getters.activeBanner.id,
										data: {
											html,
											stream: this.$store.getters.activeStream.id
										}
									});
								});
							}
						}, 1000);
					}

					this.$emit('close');
				}
			} catch (e) {
				const message = e.response?.data.error.message || e;
				this.$toast.open({
					message,
					type: 'error'
				});
			} finally {
				this.isLoading = false;
			}
		},

		async createBanner(bannerData) {
			const bannerTemplate = this.$store.getters['templates'].find(t => t.attributes.type === "banner");
			const sortedBanners = this.$store.getters.sortedBanners;

			bannerData.styles = sortedBanners.length 
				? this.$store.getters.sortedBanners[0].attributes.styles 
				: bannerTemplate.attributes.styles;
			bannerData.feedbackStats = {
				good: 0,
				bad: 0
			};
			bannerData.views = 0;

			const banner =  await this.$store.dispatch('createBanner', bannerData);
			return banner;
		},

		async updateBanner(bannerData) {
			const banner =  await this.$store.dispatch('updateBanner', {
				data: bannerData,
				id: this.bannerId
			});
			return banner;
		},

		async deleteBanner() {
			try {
				this.isLoading = true;
				await this.$store.dispatch('deleteBanner', this.bannerId);
				this.$emit('close');
			} catch(e) {
				console.error(e);
				this.$toast.open({
					message: e.response?.data.error.message || e,
					type: 'error'
				});
			} finally {
				this.isLoading = false;
			}
		},

		validateBanner() {
			if (this.banner.name.length === 0)
				throw 'Banner name is required';
			else if (this.banner.name.length < 3)
				throw 'Banner name must be at least 3 characters long';
			else if (this.banner.name.length > 100)
				throw 'Banner name must be less than 100 characters long';
			else if (this.banner.releaseDate.length === 0)
				throw 'Release date is required';	
			else if (this.mode === 'create' && this.$store.getters.banners.length) {
				const sortedBanners = this.$store.getters.sortedBanners;
				const sortedPublishedBanners = sortedBanners.filter(b => b.attributes.isPublished === true);
				const lastPublishedBanner = sortedPublishedBanners[sortedPublishedBanners.length - 1];

				if (lastPublishedBanner) {
					const lastPublishedBannerDate = new Date(lastPublishedBanner.attributes.releaseDate);
					const newBannerDate = new Date(this.banner.releaseDate);

					if (newBannerDate < lastPublishedBannerDate)
						throw 'Release date must be equal or greater than last published banner';
				}
			}

			return true;
		}
	},
	created() {
		if (this.mode === 'edit') {
			const banner = this.$deepCopy(this.$store.getters.banner(this.bannerId));
			
			this.banner = {
				name: banner.attributes.name,
				releaseDate: banner.attributes.releaseDate
			}; 
		} else {
			this.banner = {
				name: '',
				releaseDate: this.$store.getters.currentDate
			};
		}
	},
	mounted() {
		this.$refs.inputName.focus();
	}
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/_variables'
@import '../../assets/sass/_mixins'

.banner-modal
	@include modal-layout
	.title
		@include modal-title
	form
		@include modal-form
	.loading
		@include modal-loading
	.buttons
		@include modal-buttons
	button.delete
		position: absolute
		bottom: 0
		left: 0
		width: 100%
		background-color: $accent-color
		padding: 8px 0
		color: #fff
		font-size: 15px
		cursor: pointer
		transition: background-color 0.2s ease-in-out
		&:hover
			background-color: $accent-active-color

	input[type="date"]
		&::-webkit-inner-spin-button 
			-webkit-appearance: none
			display: none
		&::-webkit-calendar-picker-indicator
			background-image: url('../../assets/images/dashboard/icons/calendar.png')
			&:hover
				cursor: pointer
				filter: $accent-active-color-filter


</style>