<template>
	<div class="edit-stream-modal">
		<h2 class="title">Options</h2>

			<form @submit.prevent="saveStream">
				<div class="input-container">
					<label for="stream_name">Project name:</label>
					<input 
						type="text" 
						id="stream_name" 
						v-model="stream.name" 
						ref="inputName"
						required
					>
				</div>
				<div class="input-container">
					<label for="side_panel_caption">Side panel caption:</label>
					<div class="caption-container">
						<input type="checkbox" id="caption_checkbox" v-model="defaultCaptionCheckbox">
						<label for="caption_checkbox">Default</label>
					</div>
						<input 
							type="text" 
							id="side_panel_caption"
							v-model="stream.sidePanelCaption"
							ref="inputSidePanelCaption"
							:disabled="defaultCaptionCheckbox"
							required
						>
				</div>

				<div class="input-container">
					<label for="side_panel_color">Side panel color:</label>
					<div class="color-picker-container">
						<button
							type="button"
							class="color-picker"
							@click="toggleColorPicker"
						></button>
						<input 
							type="text"
							id="side_panel_color"
							v-model="stream.sidePanelColor"
							ref="inputSidePanelColor"
							required
						>
					</div>
					<input 
						type="color"
						style="display: none;"
						ref="inputSidePanelColorPicker"
						v-model="stream.sidePanelColor"
					>
				</div>

				<div class="input-container">
					<label for="side_panel_color">Side panel font color:</label>
					<div class="color-picker-container">
						<button
							type="button"
							class="color-picker"
							@click="toggleFontColorPicker"
						></button>
						<input 
							type="text"
							id="side_panel_color"
							v-model="stream.sidePanelFontColor"
							ref="inputSidePanelFontColor"
							required
						>
					</div>
					<input 
						type="color"
						style="display: none;"
						ref="inputSidePanelFontColorPicker"
						v-model="stream.sidePanelFontColor"
					>
				</div>

				<div class="loading" v-if="isLoading"></div>
				<div class="buttons" v-else>
					<button 
						type="button"
						class="secondary cancel"
						@click="closeModal"
					>Cancel</button>

					<button 
						type="submit"
						class="main save"
					>Save</button>
				</div>
			</form>
	</div>
</template>

<script>
export default {
	name: 'EditStreamModal',
	data: () => ({
		stream: {
			name: '',
			sidePanelCaption: '',
			sidePanelColor: '',
			sidePanelFontColor: '',
		},
		isLoading: false,
		defaultCaptionCheckbox: false
	}),
	computed: {
		activeStream() {
			return this.$store.getters.activeStream;
		},
		defaultCaption() {
			return `News on ${this.stream.name}`;
		}
	},
	methods: {
		async saveStream() {
			try {
				await this.$store.dispatch('updateStream', { 
					data: this.stream, 
					id: this.activeStream.id
				});
				this.$toast.success('Stream updated successfully');
				this.closeModal();
			} catch (e) {
				this.$toast.error('Error on stream update');
			}
		},

		toggleColorPicker() {
			this.$refs['inputSidePanelColorPicker'].click();
		},
		toggleFontColorPicker() {
			this.$refs['inputSidePanelFontColorPicker'].click();
		},

		setStream() {
			const activeStream = this.$deepCopy(this.activeStream.attributes);

			Object.keys(this.stream).forEach(key => {
				this.stream[key] = activeStream[key];

				if (key === 'sidePanelCaption' && !this.stream[key]) 
					this.stream[key] = `News on ${activeStream.name}`;

				else if (key === 'sidePanelColor' && !this.stream[key]) 
					this.stream[key] = '#2b579a';

				else if (key === 'sidePanelFontColor' && !this.stream[key]) 
					this.stream[key] = '#ffffff';
			});
		},

		closeModal() {
			this.$emit('close');
		}
	},
	created() {
		this.setStream();
		if (this.defaultCaption === this.stream.sidePanelCaption) {
			this.defaultCaptionCheckbox = true;
		}
	},
	watch: {
		defaultCaptionCheckbox() {
			if (this.defaultCaptionCheckbox) {
				this.stream.sidePanelCaption = this.defaultCaption;
			} else {
				this.stream.sidePanelCaption = '';
				this.$nextTick(() => {
					this.$refs['inputSidePanelCaption'].focus();
				});
			}
		},
		'stream.name': {
			handler () {
				if (this.defaultCaptionCheckbox) {
					this.stream.sidePanelCaption = this.defaultCaption;
				}
			},
			deep: true
		}
	}
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/_variables'
@import '../../assets/sass/_mixins'

.edit-stream-modal
	@include modal-layout
	.title
		@include modal-title
	form
		@include modal-form
		.input-container
			.caption-container
				display: flex
				align-items: center
				margin: 10px 0
				margin-top: 15px
				label
					color: $accent-color
					margin: 0 10px
					margin-right: 20px
					cursor: pointer
					font-size: 16px
					&:hover
						text-decoration: $accent-active-color
			.color-picker-container
				display: flex
				align-items: center
				button.color-picker
					display: inline-block
					width: 16px
					height: 16px
					background: url('../../assets/images/dashboard/icons/drop.svg') no-repeat
					background-size: contain
					margin-right: 10px
					cursor: pointer
				input
					flex: 1

	.loading
		@include modal-loading
	.buttons
		@include modal-buttons
</style>