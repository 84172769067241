import { render, staticRenderFns } from "./DuplicateBannerModal.vue?vue&type=template&id=305e8ddd&scoped=true"
import script from "./DuplicateBannerModal.vue?vue&type=script&lang=js"
export * from "./DuplicateBannerModal.vue?vue&type=script&lang=js"
import style0 from "./DuplicateBannerModal.vue?vue&type=style&index=0&id=305e8ddd&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305e8ddd",
  null
  
)

export default component.exports